import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import scrollHook from "../Hooks/scrollHook";
import "../animations.css";
import Faq_component from "../Component/faq";

const Services = () => {
  const all_faq_arr = [
    {
      H4: "Why is a well-developed website important for my business?",
      pera: "Having a good website is super important for your business. It's like your digital storefront that's open 24/7, letting people check out what you offer anytime. A well-made website not only gives a great first impression but also makes your business look trustworthy. It's like a virtual place where you can show off your products or services and tell your story. Think of your website as a friendly guide for customers, making it easy for them to learn about your business and contact you. It's like having a reliable assistant that works even when you're sleeping! And here's the cool part – a website helps your business stand out in the online world. It's like a tool that not only attracts customers but also lets you see how they behave, so you can make smart decisions to grow your business. So, having a good website isn't just a nice-to-have; it's a must-have for a successful and customer-friendly business.",
    },
    {
      H4: "Can you redesign my existing website for a modern look?",
      pera: "Absolutely! We're really good at making your online stuff look modern and cool. We focus on updating how things appear, like giving your digital home a fresh touch. Whether it's making your website responsive, adding new info, or giving it a total makeover, we're here to make your online space look awesome for today's crowd. Our main goal is to make your online place not just look good but also feel right for the times we're in. We're like decorators for your digital world, making sure it stands out and feels super modern. Let us be the ones to give your online look a fresh and trendy vibe! ",
    },
    {
      H4: "What role does SEO play in website development?",
      pera: "Think of SEO like a magic trick for your website – it helps it appear higher up when people search on Google for things related to what your business offers. So, when someone looks for stuff similar to what you do, your website has a better chance of popping up near the top. With SEO, we work on making your website climb higher in the list of search results. This way, when someone is searching for something, your business provides, there's a good chance they'll find your website quickly. It's like making your business stand out in a big crowd, so more people notice it when they're looking for things online. In a nutshell, SEO is that helps your website get seen by the right people at the right time. So, incorporating SEO isn't just about making your website look good to search engines; it's about making it stand out on the search engine, ensuring more people discover and explore what you have to offer. ",
    },
    {
      H4: "Can I update my website content after it's developed?",
      pera: "Absolutely! You can definitely update your website content even after it's been developed. We provide a user-friendly Content Management System (CMS) that empowers you to make changes to your website without needing technical expertise. With the CMS, you can easily add new content, modify text, upload images, and make updates to keep your website current and relevant.Think of the CMS as a toolkit that puts you in control of your website's content. It's designed to be intuitive and straightforward, allowing you to manage your online presence without any hassle. Whether you want to add new products, update information, or share the latest news, the CMS makes it simple for you to keep your website up-to-date. So, even after the initial development, you have the flexibility to make changes and ensure your website reflects the latest aspects of your business.",
    },
    {
      H4: "What ongoing support and maintenance do you offer?",
      pera: "We provide comprehensive ongoing support and maintenance to ensure your website remains in top-notch condition. Our services include regular updates, security monitoring, and technical assistance to address any issues that may arise. With our maintenance packages, you can enjoy peace of mind knowing that your website is well taken care of.Our support extends beyond mere technical aspects. We're here to assist you with content updates, feature enhancements, and any adjustments you may need. Whether it's troubleshooting technical glitches or implementing new functionalities, our team is dedicated to keeping your website running smoothly. Additionally, we offer regular performance evaluations and analytics reviews to ensure your website continues to meet its goals. Our goal is to provide you with a hassle-free experience, allowing you to focus on your business while we handle the technicalities of maintaining a reliable and high-performing website.",
    },
    {
      H4: "What is digital marketing? ",
      pera: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. It plays an important role in broadening reach, increasing visibility, and driving growth by utilizing various online channels and platforms. Our company specializes in providing a range of services to empower businesses in the digital world.",
    },
    {
      H4: "What services do you provide? ",
      pera: "The services we offer include Search Engine Optimization (SEO), which involves optimizing your website to improve its visibility on search engines, thereby driving organic traffic and establishing industry authority. Social media marketing is another key service that focuses on creating and implementing strategies to engage and grow your audience across various social media platforms. Content creation, an integral part of digital marketing, involves developing compelling and relevant content to capture the attention of your target audience. We also Design and Develop Websites from E-commerce to Personal Websites we have experts for all.  Additionally, paid advertising allows businesses to reach specific demographics and target audiences through paid promotions on platforms like Google Ads or social media. We help business to increases their ROI through our paid advertising service.",
    },
    {
      H4: "How can digital marketing benefit my business? ",
      pera: "Digital marketing provides numerous benefits for businesses, including enhancing online presence, attracting targeted audiences, generating leads, and ultimately increasing sales with measurable results. To ensure the effectiveness of our strategies, we tailor them specifically to your business by conducting a thorough analysis of your industry, business model, and target audience. This ensures that our efforts are aligned with your goals and objectives.",
    },

    {
      H4: "What is SEO's importance? ",
      pera: "The importance of SEO cannot be overstated, as it is a fundamental aspect of digital marketing. SEO optimization involves fine-tuning your website to meet the criteria of search engine algorithms, leading to improved visibility, increased organic traffic, and the establishment of authority within your industry. SEO is like the backbone of digital marketing—it's super important! Think of it as giving your website a makeover to impress search engines like Google. This makeover, called SEO optimization, involves tweaking your site to match what these search engine robots love. The result? Your website becomes a shining star on the internet.Picture this: people looking for businesses like yours online, and thanks to SEO, your website pops up more often. It's not just about getting noticed; it's about becoming the go-to expert in your industry.  ",
    },

    {
      H4: "How is campaign success measured?",
      pera: "Checking how well your digital marketing is doing is super important for making it even better. We use special tools called Key Performance Indicators (KPIs) to see how things are going.Imagine your website is like a store, and website traffic is like counting how many people walk in. Conversion rates tell us how many of those visitors become customers, just like knowing how many people who enter a store end up buying something. Social media engagement is like keeping an eye on how much people like, share, and comment on your posts.These KPIs are like your digital dashboard, showing us what's working well and what we can improve. It's not just about numbers; it's about making sure your digital strategies are connecting with people. Think of it like giving your digital marketing a report card to make it even more awesome!",
    },
    {
      H4: "What sets your company apart?  ",
      pera: "What makes our company special is how we mix creativity with smart strategies using data. It's like putting together strategic ideas with precise plans, making our approach one-of-a-kind in the busy digital marketing world.We really love working with our clients. It's not just about what we think; it's about what you think too. Your ideas are super important, and we make sure they're part of everything we do. It's like making a cool project together.In the big world of digital marketing, our standout feature is our promise to show you real, measurable results. We don't just talk about doing cool stuff; we make sure that what we do actually works and makes a difference you can see. Think of us as guides, helping you navigate the digital world with a map that leads to clear and impactful outcomes.Our strength lies in bringing together creativity, teamwork, and a focus on results. We're not just running campaigns; we're building digital adventures that leave a lasting mark.",
    },
    {
      H4: "Can I choose specific services?   ",
      pera: "Whether you're looking for a comprehensive digital marketing package or specific services to meet your business goals, we offer the flexibility for you to choose individual services based on your unique needs. Starting with us is simple – reach out through our website or give us a call to initiate a consultation where we can discuss how our services can be aligned with the specific needs of your business.",
    },

    {
      H4: "Why is Social Media Marketing ?",
      pera: "Social Media Marketing (SMM) is a digital marketing strategy that involves using social media platforms to connect with the target audience, build brand awareness, and promote products or services. It encompasses various activities, including creating and sharing content on social media networks, engaging with followers, running paid advertising campaigns, and analyzing performance metrics.",
    },
    {
      H4: "How can SMM benefit my business?",
      pera: "Social Media Marketing (SMM) acts as a powerful tool for your business. It's like a friendly guide that introduces your brand to a wider audience. Through platforms like Facebook or Instagram, SMM helps in sharing interesting content, making your brand more visible and memorable. SMM also lets you show ads to specific groups of people who are most likely interested in what you offer. This means your messages reach the right folks, making your marketing super effective. Plus, when you share exciting things on social media, more people visit your website and might become interested in your products or services. In a nutshell, SMM is your business's sidekick, boosting its popularity, helping it make follower, and bringing in potential customers from all around the world.",
    },
    {
      H4: "Can SMM help my small business compete with larger competitors?",
      pera: "Absolutely! Social Media Marketing (SMM) is like a powerful tool for small businesses. It helps them compete with the big players without spending a ton of money. With SMM, you can reach the specific people who are interested in what you offer. Imagine it as a super affordable way to get your business noticed in the crowded online world. Small businesses use SMM to create cool posts and connect directly with people who might become customers. It's a way to show off what makes your business special and build a group of fans. SMM is not just about competing; it's about making your business stand out and be remembered. It's like having a friendly assistant that helps small businesses shine and make a big impact online.",
    },
    {
      H4: "What social media platforms are suitable for my business?",
      pera: "Our approach involves a thorough analysis of your business goals and target audience, guiding us to recommend the most suitable social media platforms. Understanding your unique objectives allows us to tailor our suggestions for optimal results. For businesses aiming to build a broad online presence and engage diverse audiences, platforms like Facebook offer versatile solutions. If your business thrives on visually appealing content, Instagram becomes a strategic choice. Twitter, with its real-time updates and quick interactions, suits those seeking immediate engagement. LinkedIn is recommended for businesses focusing on professional networking and B2B interactions. By carefully aligning your goals and audience characteristics, we ensure that our platform recommendations, whether it's Facebook, Instagram, Twitter, or LinkedIn, effectively serve your business objectives, enhancing your digital presence and engagement within your target market.",
    },
    {
      H4: "How JK INFOSYS GLOBAL help my business with social media marketing?",
      pera: "As a top social media company in Ahmedabad, JK INFOSYS GLOBAL is here to make your business shine online. We're social media experts, creating a plan that perfectly fits what you want to achieve. Our team, who really knows their stuff, will take care of your social media accounts. They'll make cool and interesting posts and run ads that make sure the right people see them. At JK INFOSYS GLOBAL, we get that every business is different. So, we sit down with you to understand exactly what you want. Then, we make a super-smart plan that not only matches your goals but also makes your business stand out online. Our job doesn't stop at just posting things. We dig deep and run special campaigns to reach the people you really want to connect with. Choosing us means you're not just getting a social media service; you're getting a buddy who's all about making your business look awesome online in Ahmedabad and everywhere else.",
    },
    {
      H4: "How do you measure the success of an SMM campaign?",
      pera: "We keep a close eye on how well your campaigns are doing by looking at some important numbers. These numbers help us see how much people are liking and engaging with your posts (that's the engagement rate). We also check how many people are seeing your stuff (that's the reach). But it's not just about being seen; we want to know if people are taking action, like clicking or buying something (that's conversions). And the big one – Return on Investment (ROI) – tells us how much you're gaining from what you're investing. It's like making sure you're getting more out of what you put in. So, with these numbers, we tell you the story of how well your campaigns are doing. It's not just about stats; it's about showing you the real impact and success your campaigns are bringing to your business in a simple and easy-to-understand way.",
    },
    {
      H4: "Can you handle both organic and paid social media strategies?",
      pera: "For sure! We've got a smart plan that combines two powerful strategies to make your business shine on social media. It's like having a double-sided approach that covers all the bases for maximum impact. Firstly, there's the organic side of things. This is where we create interesting posts and share them without any paid promotions. It's like the natural way of getting attention, and it's great for building a genuine connection with your audience. Then, we add a dash of paid strategy. This involves putting a bit of budget behind certain posts to make sure they reach a bigger audience. It's like giving a little boost to the posts that are already doing well, so more people see them. By blending these two strategies – the natural, organic approach and a bit of a boost with paid promotions – we create a complete plan that ensures your business stands out and gets noticed by the right people. It's like having the best of both worlds for a super effective social media game. ",
    },
    {
      H4: "Is SMM only for B2C businesses, or does it benefit B2B businesses as well?",
      pera: "Absolutely! Social Media Marketing (SMM benefits both types of businesses – the ones selling directly to consumers (B2C) and the ones selling to other businesses (B2B). For B2C businesses, SMM is like a direct line to your customers. It helps you talk to them, understand what they like, and build a professional relationship. You can show off your products, answer questions, and create a buzz that keeps people interested and coming back for more. Now, for B2B businesses, it's a bit different but just as awesome. SMM becomes your tool to show how smart and expert you are in your industry. You can share valuable insights, connect with other businesses, and even find new leads. It's like building a reputation as the go-to expert in your field, making other businesses want to work with you. ",
    },
    {
      H4: "How much social media marketing cost?",
      pera: "Certainly! When it comes to the cost of Social Media Marketing (SMM), it's like choosing from a menu based on what your business needs. The price can vary depending on the scope and goals of your project. At JK INFOSYS GLOBAL, we understand that each business is unique, and so are its budget constraints. Our social media management services range from Rs. 32,000 to over Rs. 1,00,000, catering to businesses of all sizes and budgets. Whether you're a small startup or a well-established enterprise, we work closely with you to tailor our services to fit your specific needs. Our goal is to provide more than just social media posts; we want to create marketing strategies that do wonders for your business. From generating leads to boosting sales and fostering brand loyalty, we've got the tools to make your business shine in the social media spotlight. So, whether your budget is big or small, we're here to deliver effective social media marketing that suits your unique requirements. ",
    },

    {
      H4: "What is SEO, and why is it important for my business?",
      pera: "SEO, or Search Engine Optimization, is a comprehensive set of strategies and practices aimed at enhancing a website's visibility on search engines like Google. Its importance for businesses lies in its ability to expand online presence, drive organic traffic, and significantly improve visibility in the digital landscape. By optimizing various elements, SEO ensures that your business is not just a part of the online conversation but a prominent player, attracting potential customers and fostering growth.",
    },
    {
      H4: "What SEO services do you offer?",
      pera: "Our SEO services bound a complete approach to ensure a comprehensive optimization strategy tailored to your business needs. From on-page optimizations and content creation to backlink building, keyword research, and technical SEO, our services are designed to address every aspect of enhancing your online presence and driving organic traffic.",
    },
    {
      H4: "How do you determine the right keywords for my business?",
      pera: "Choosing the right words for your online success is like uncovering hidden treasures. We don't just pick any words – it's a detailed process, almost like being an internet detective. We look closely at your industry, understand what your customers are interested in, and check out what your competitors are up to. Picture it as putting together a puzzle – we find the pieces that make your business unique and then figure out the best words that will bring the right visitors to your website. These words are like magic keys, opening the door to quality traffic and making sure your online strategy fits perfectly with what your business aims to achieve. It's about making your business shine in the online world by using the language that speaks directly to your customers and attracts the attention you want. ",
    },

    {
      H4: "Can I choose specific SEO services based on my business goals?",
      pera: "Certainly! Our flexible approach allows you to tailor our services to meet your unique business objectives. Whether you prefer individual services or a comprehensive package, we offer the versatility to align our strategies with your specific requirements, ensuring that your SEO efforts are customized to achieve the desired outcomes.",
    },
    {
      H4: "How do you measure the success of SEO campaigns?",
      pera: "The success of our SEO campaigns is measured through a robust set of key performance indicators (KPIs). These include monitoring website traffic, analyzing conversion rates, tracking keyword rankings, and assessing social media engagement. Regular and detailed reports are provided to keep you informed about the progress and effectiveness of the implemented strategies, allowing for continuous refinement and optimization.",
    },
    {
      H4: "Do you offer local SEO services for businesses targeting a specific area?",
      pera: "Absolutely! Our suite of services includes specialized local SEO strategies designed to enhance the visibility of businesses targeting specific geographic locations. This involves optimizing for local keywords, refining Google My Business profiles, and ensuring accurate and consistent business information across local directories, all aimed at capturing the attention of local customers.",
    },
    {
      H4: "Is SEO a one-time effort, or does it require ongoing maintenance?",
      pera: "SEO is an ongoing and dynamic process that evolves with changes in search engine algorithms and industry trends. While initial optimizations set the foundation, continuous efforts are essential for adapting to algorithm updates, staying competitive in a dynamic digital landscape, and maintaining or improving search engine rankings over the long term. Regular maintenance ensures that your SEO strategies align with the latest best practices, contributing to sustained success.",
    },
    {
      H4: "How do you stay updated with the latest SEO trends and algorithm changes?",
      pera: "Our commitment to staying at the forefront of the industry involves a proactive approach to continuous learning and adaptation. Our team regularly attends relevant conferences, engages in industry forums, and closely monitors algorithm updates from major search engines. This ensures that our strategies are not only current but also aligned with the latest SEO best practices, allowing us to provide our clients with cutting-edge solutions.",
    },
    {
      H4: "What sets your SEO services apart from others in the market?",
      pera: "What distinguishes our SEO services is a unique blend of creativity with data-driven strategies. We prioritize collaboration with our clients, ensuring that their insights and goals are integrated into our approach. Our services are not one-size-fits-all; instead, they are customized based on a thorough analysis of your business, industry, and target audience. This tailored approach, combined with our commitment to delivering measurable and sustainable results, sets us apart in the competitive landscape of digital marketing, ensuring that your business stands out and thrives online.",
    },

    {
      H4: "What digital marketing services do you offer?",
      pera: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and all kind of social media platform. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. It plays an important role in broadening reach, increasing visibility, and driving growth by utilizing various online channels and platforms. Our company specializes in providing a range of services to empower businesses in the digital world.We provide a comprehensive range of digital marketing services which include search engine optimization (SEO), social media management, advertising, content marketing, web design and development, branding and email marketing. Our goal is to create a customized strategy that aligns with your business objectives and drives meaningful results. ",
    },
    {
      H4: "What you offer in your website development service?",
      pera: "Our web development services include everything from creating responsive and user-friendly websites to custom web applications. Whether you need a new website or want to change your existing one, we focus on delivering a seamless online experience for your users while aligning with your brand identity. From E-commerce to personal website  we provide all kind of website development. Our Website are build with the help trendy web development technologies which include Java-script, Node-JS, React-JS and WordPress.",
    },
    {
      H4: "What kind of social media marketing service do you provide?",
      pera: "Social Media Marketing (SMM) is a digital marketing strategy that involves using social media platforms to connect with the target audience, build brand awareness, and promote products or services. It encompasses various activities, including creating and sharing content on social media networks, engaging with followers, running paid advertising campaigns, and analysing performance metrics. Social media marketing is a powerful tool for building brand awareness, engagement with your target audience, and driving website traffic. We create strategic social media campaigns tailored to your business objectives, ensuring increased visibility, audience engagement, and ultimately, conversions. We offer a wide range of services which include profile setup, profile optimization, content creation, audience research, social media campaigns setup, analytics and reporting for all social media platforms like Facebook, Instagram, Twitter, LinkedIn and many more. ",
    },
    {
      H4: "What  is SEO and how it helps to grow my business?",
      pera: "SEO, or Search Engine Optimization, is a comprehensive set of strategies and practices aimed at enhancing a website's visibility on search engines like Google. Its importance for businesses lies in its ability to expand online presence, drive organic traffic, and significantly improve visibility in the digital landscape. By optimizing various elements, SEO ensures that your business is not just a part of the online conversation but a prominent player, attracting potential customers and fostering growth. Search Engine Optimization (SEO) is essential for improving your website's visibility on search engines. Our SEO services focus on optimizing your website's content, structure, and performance to rank higher in search results. This leads to increased organic traffic, higher conversion rates, and improved overall online visibility for your business.",
    },
    {
      H4: "What graphic design services do you offer?",
      pera: "Graphic Design is like the art of using pictures and colours to tell your audience that what is your brand is all about. what kind of service or product you offer. It's the creative way of making your brand stand out in various social media platform. Our graphic design services cover everything from logos and branding elements to marketing collateral. By creating visually appealing and impactful designs, we help elevate your brand, making it more memorable and recognizable to your target audience.",
    },
    {
      H4: "What types of branding services do you offer?",
      pera: "Branding is about making your business recognizable and creating a positive impression. It's like giving your business a special identity that people can easily connect with and remember. A strong brand helps your business stand out in a crowded market and build trust with your customers. So, it's not just a logo; it's about the entire strategy that we build to make your business a impactful brand. In Ahmedabad, like JK INFOSYS GLOBAL, a branding company, provides various services such as brand strategy, logo design, visual identity development, messaging, marketing campaigns, and brand management.",
    },

    {
      H4: "What graphic design services do you offer?",
      pera: "Our graphic design services cover a broad spectrum to meet your diverse needs. From crafting eye-catching logos that represent your brand identity to developing comprehensive branding solutions, we also specialize in designing marketing collateral like brochures and business cards. Additionally, we create impactful social media graphics and provide website design elements for a strong online presence. Our goal is to visually articulate your brand's essence and messages with creativity and precision.",
    },
    {
      H4: "What is graphic design and why it is important for my business?",
      pera: "Graphic Design is like the art of using pictures and colours to tell your audience a story. It's the creative way of making your brand stand out and helping people understand what your brand is all about. Imagine it as the visual personality of your brand – the colours, pictures, and words all work together to create a message that people can easily see and remember.So, Graphic Design isn't just about making things look pretty; it's about making your brand memorable and instantly recognizable. It's like the face of your brand that speaks to people without words, helping them connect with what you're all about.",
    },
    {
      H4: "Can you help to create unique logo?",
      pera: "Absolutely! We're experts at making logos and crafting brand identities for all kinds of businesses. Think of a logo as your brand's signature – it's the special symbol that represents who you are. When we create brand identity, it's like giving your business a unique personality. We choose colours, fonts, and styles that make your brand stand out and be remembered. Whether you're just starting or looking to refresh your brand, our specialty is making sure your business looks awesome and leaves a lasting impression. So, if you need a logo that speaks volumes about your business, we've got you covered!",
    },
    {
      H4: "Can you design both print and digital media?",
      pera: "Certainly! Our skilled team is here to craft all kinds of designs, whether it's for the digital world like websites and social media or for things you can hold, like business cards, brochures, and banners. It's like having a team of creative experts ready to make your online and offline presence look fantastic. For digital stuff, we'll make your website not just functional but visually appealing, and your social media will pop with eye-catching graphics. On the print side, we'll design business cards that leave a lasting impression, brochures that tell your story beautifully, and banners that grab attention. Think of us as your go-to design expert, turning your ideas into visuals that stand out in both the online and real-world spaces. So, if you need designs that wow, we've got you covered! ",
    },

    {
      H4: "What is E-commerce?",
      pera: "What distinguishes our SEO services is a unique blend of creativity with data-driven strategies. We prioritize collaboration with our clients, ensuring that their insights and goals are integrated into our approach. Our services are not one-size-fits-all; instead, they are customized based on a thorough analysis of your business, industry, and target audience. This tailored approach, combined with our commitment to delivering measurable and sustainable results, sets us apart in the competitive landscape of digital marketing, ensuring that your business stands out and thrives online.",
    },
    {
      H4: "What type of  E-commerce website you build?",
      pera: "There are three major types of ecommerce websites that we are build Business to Business (B2B) where the practice is of selling online from one business to another. Business to Consumer (B2C) where businesses put up their products online, allowing consumers to purchase the products (such as Amazon). Consumer to Consumer, where consumers place an image of the product or item they want to sell online and another consumer buys it (such as eBay). ",
    },
    {
      H4: "Do I need an ecommerce website to sell product online?",
      pera: "Selling products online is doable without an e-commerce website, but using a dedicated platform comes with many benefits. It helps with tasks such as handling inventory, ensuring secure payment processing, and efficiently tracking orders. Think of it this way – with an e-commerce website, your products are showcased 24/7, reaching people worldwide at any time. It's like having a shop that never closes! Plus, it makes managing your stuff way easier. You can organize your products, make sure payments are safe, and easily track all the orders coming in. So, while you can sell online without a dedicated website, having one is like having a trusty assistant that makes everything run smoother in the online world. ",
    },
    {
      H4: "Can you assist with setting up and managing online payment gateways?",
      pera: "Absolutely! We can help integrate and manage various online payment gateways, ensuring secure and seamless transactions for your customers.",
    },
    {
      H4: "Can you help with website updates and maintenance?",
      pera: "Yes, we offer regular website updates and maintenance to keep your online store running smoothly, including software updates, security patches, and other necessary improvements. We're here to make sure your website stays in up to standard shape. We have a dedicated team that regularly checks and improves your online store, making sure everything runs smoothly and stays safe. We handle the technical stuff, like updating the software and adding security measures – all the things that happen behind the scenes.Think of it as having a professional expert for your website, ensuring it's always up-to-date and secure. This way, you can focus on running your business while we take care of the each and every detail to keep your online store in top-notch condition. It's all about giving you peace of mind, knowing that your website is in good hands and ready to impress your customers.",
    },
    {
      H4: "What measures do you take for website security?",
      pera: "Absolutely! Your online store's safety is our top priority. We set up strong security measures, kind of like putting a fortress around your e-commerce website. This ensures that all the important stuff, like customer details and transaction info, stays safe and sound. We use the latest tech tricks to guard against any mess with your website data. So, when your customers shop on your site, they can trust that their information is in good hands. It's like having a virtual security guard that works 24/7, making sure your e-commerce website is both responsive and safe and your customers can shop with peace of mind.",
    },
    {
      H4: "How can I get started with your e-commerce website management service?",
      pera: "Getting started is easy! Simply contact our team, and we'll guide you through the onboarding process, understanding your specific needs and tailoring our services to suit your e-commerce goals.",
    },
  ];

  return (
    <div className=" bg-gray-100 ">
      <Helmet>
        <title>Jk Infosys Global Services </title>
        <meta
          name="description"
          content="we take pride in offering a comprehensive range of services designed to meet your unique needs. Our commitment to excellence and innovation drives everything we do."
          data-rh="true"
        />
      </Helmet>

      <div className="lg3:px-72 px-5 lg2:px-32">
        <div
          className="px-5   "
          style={{
            backgroundImage: `url(../img/services_page_header_image.png)`,
          }}
        >
          <div className="px-5 py-10 lg:grid md:px-20   grid-cols-3 ">
            <div className="col-span-2 my-auto text-white">
              <h1 className="  py-5 md:py-10 text-5xl   md:text-7xl  lg3:text-9xl   bebas-neue-regular">
                OUR SERVICES
              </h1>
            </div>
            <div className=" ">
              <img
                src="../img/computer_service_page.png"
                alt="computer_service_page"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="md:flex items-center md:px-20  justify-between">
            <p className=" text-white text-xl ">
              We are best Digital Marketing Company in Ahmedabad
            </p>
            <div>
              <div className="py-10">
                <button className="btn" aria-label="TALK TO US!">
                  <Link to="/contact-us" onClick={scrollHook}>
                    <span>TALK TO US!</span>
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="px-5  lg:grid    grid-cols-3 "
          style={{
            backgroundImage: `url(../img/service_digital_marketing_backround_image.png)`,
          }}
        >
          <div className="md:py-10 ">
            <img
              src="../img/services_digital_marketing_side-image.png"
              alt="image"
              width="100%"
              height="100%"
            />
          </div>
          <div className="col-span-2 text-right my-auto">
            <h3 className="  py-5 md:py-10 text-5xl  md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              DIGITAL MARKETING
            </h3>
            <p className=" text-xl ">
              Being a trusted{" "}
              <NavLink
                to={"/digital-marketing"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-blue-950 bebas-neue-regular cursor-pointer"
              >
                Top Digital Marketing Company in Ahmedabad,
              </NavLink>{" "}
              our experience as a digital marketing consultant guarantees that we have the know-how
              to offer services that meet our client's evolving needs. We promise to deliver
              excellent and quality services within the agreed-upon time frame, ensuring our clients
              receive the best possible support.
            </p>
          </div>
        </div>

        <div
          className="px-5 py-10 lg:grid md:px-20   grid-cols-3 "
          style={{ backgroundImage: `url(../img/work_with_us_web.png)` }}
        >
          <div className="col-span-2 my-auto text-white">
            <h3 className="  py-5 md:py-10 text-5xl   md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              WEBSITE DEVELOPMENT
            </h3>
            <p className=" text-xl ">
              At JK INFOSYS GLOBAL, recognized as the premier{" "}
              <NavLink
                to={"/web-development"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-white bebas-neue-regular cursor-pointer"
              >
                Website Design and Development Company in Ahmedabad,
              </NavLink>{" "}
              we don't just create static websites. We specialize in crafting dynamic and highly
              customized sites and portals that seamlessly adapt to various devices, from small
              smartphones to large desktop screens. Whether you're aiming for an E-commerce platform
              to sell products, a branding site to showcase services and accomplishments, or a
              combination of both, we tailor our solutions to align with your ideas and
              requirements, adding a touch of creative innovation from our hands.
            </p>
          </div>
          <div className="md:py-10 ">
            <img
              src="../img/webdevelopment_side_image.png"
              alt="webdevelopment_side_image"
              width="100%"
              height="100%"
            />
          </div>
        </div>

        <div
          className="px-5 py-10 lg:grid md:px-20   grid-cols-3 "
          style={{ backgroundImage: `url(../img/work_with_us_seo.png)` }}
        >
          <div className="md:py-10 ">
            <img
              src="../img/socialmedia_side_image.png"
              alt="socialmedia_side_image"
              width="100%"
              height="100%"
            />
          </div>
          <div className="col-span-2 text-right my-auto">
            <h3 className="  py-5 md:py-10 text-5xl  md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              SOCIAL MEDIA MARKETING
            </h3>
            <p className=" text-xl ">
              We're a{" "}
              <NavLink
                to={"/social-media-marketing"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-blue-950 bebas-neue-regular cursor-pointer"
              >
                Social Media Marketing Company in Ahmedabad ,
              </NavLink>{" "}
              dedicated to helping businesses grow online. Our services include Social Media
              Management, Content Creation, Campaign Strategy, Performance Marketing, and Influencer
              Marketing, making us the best in Ahmedabad.
            </p>
          </div>
        </div>

        <div
          className="px-5 py-10 lg:grid md:px-20   grid-cols-3 "
          style={{ backgroundImage: `url(../img/bg_services_seo_img.png)` }}
        >
          <div className="col-span-2 my-auto text-white">
            <h3 className="  py-5 md:py-10 text-5xl   md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              SEO
            </h3>
            <p className=" text-xl ">
              As a{" "}
              <NavLink
                to={"/seo"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-white bebas-neue-regular cursor-pointer"
              >
                Top SEO Company in Ahmedabad,
              </NavLink>{" "}
              we specialize in personalized SEO services. Whether you're starting from scratch or
              giving your website a makeover, think of us as your go-to web development experts too.
              Our skilled SEO consultants follow the rules, bringing real value to your business and
              making sure more people visit your website.
            </p>
          </div>
          <div className="md:py-10 ">
            <img
              src="../img/services_seo_side_image.png"
              alt="services_seo_side_image"
              width="100%"
              height="100%"
            />
          </div>
        </div>

        <div
          className="px-5 py-10 lg:grid md:px-20   grid-cols-3 "
          style={{
            backgroundImage: `url(../img/services_branding_background_image.png)`,
          }}
        >
          <div className="md:py-10 ">
            <img
              src="../img/services_branding_side_image.png"
              alt="services_branding_side_image"
              width="100%"
              height="100%"
            />
          </div>
          <div className="col-span-2 text-right my-auto">
            <h3 className="  py-5 md:py-10 text-5xl  md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              BRANDING
            </h3>
            <p className=" text-xl ">
              At JK INFOSYS GLOBAL, a{" "}
              <NavLink
                to={"/branding"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-blue-950 bebas-neue-regular cursor-pointer"
              >
                Top Branding Company in Ahmedabad,
              </NavLink>{" "}
              when we say we make your brand stand out, it goes beyond just looking different. We
              aim to make it unique and instantly recognizable, so consumers know it's your service
              or product that meets their needs.
            </p>
          </div>
        </div>

        <div
          className="px-5 py-10 lg:grid md:px-20   grid-cols-3 "
          style={{
            backgroundImage: `url(../img/service_bg_graphic_design.png)`,
          }}
        >
          <div className="col-span-2 my-auto text-white">
            <h3 className="  py-5 md:py-10 text-5xl   md:text-7xl  lg3:text-9xl   bebas-neue-regular">
              GRAPHIC DESIGN
            </h3>
            <p className=" text-xl ">
              Welcome to JK INFOSYS GLOBAL{" "}
              <NavLink
                to={"/graphic-design"}
                onClick={scrollHook}
                className="hover:bg-blue-950 underline hover:text-white text-white bebas-neue-regular cursor-pointer"
              >
                Graphic Design Company in Ahmedabad,
              </NavLink>{" "}
              the pinnacle of design excellence where innovation meets imagination. Our Graphic
              Design Services go beyond the ordinary, offering you a world of visually stunning
              solutions crafted to elevate your brand to new heights. Immerse yourself in the
              artistry of design as we unfold a vast array of offerings tailored to meet your every
              creative need.
            </p>
          </div>
          <div className="md:py-10 ">
            <img
              src="../img/service_graphic_design_side_image.png"
              alt="service_graphic_design_side_image"
              width="100%"
              height="100%"
            />
          </div>
        </div>

        <Faq_component arr={all_faq_arr} pera={"FREQUENTLY ASKED QUESTIONS"} />
      </div>
    </div>
  );
};

export default Services;
