import React, { useState, useEffect } from "react";
import scrollHook from "../Hooks/scrollHook";
import { Link } from "react-router-dom";

const Header1 = ({ img, pera, alt }) => {
  return (
    <div style={{ backgroundImage: `url("../img/home_header_image.png")` }} className="px-5   ">
      <div className=" py-10 grid md:px-20   grid-cols-6 ">
        <div className="md:col-span-4 col-span-3 my-auto text-white">
          <p className="  py-5 md:py-10   text-xl sm:text-3xl  md:text-4xl lg:text-6xl lg3:text-8xl   bebas-neue-regular text-blue-950">
            {pera}
          </p>
        </div>
        <div className="md:col-span-2 col-span-3">
          <img src={img} alt={alt} height={"100%"} width={"100%"} />
        </div>
      </div>
      <div className="md:flex items-center md:px-20  justify-between">
        {/* <p className="  text-xl  text-blue-950">
              We are best Digital Marketing Company in Ahmedabad
            </p> */}
        {/* <div>
              <div className="py-10">
                <button className="btn">
                  <Link to="/contact-us" onClick={scrollHook}>
                    <span>TALK TO US!</span>
                  </Link>
                </button>
              </div>
            </div> */}
      </div>
    </div>
  );
};

export default Header1;
