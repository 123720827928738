import React from "react";
import { Helmet } from "react-helmet-async";
import Section from "../Component/Cartsection";
import Header1 from "../Component/header1";
import { NavLink } from "react-router-dom";

import Faq_component from "../Component/faq";

const GraphicDesign = () => {
  const GraphicDesign_arr_1 = [
    {
      title: "Logo Design",
      pera: "Embark on a transformative journey to establish a brand identity that resonates. Our seasoned designers collaborate closely to birth a symbol that not only mirrors your brand values but leaves a lasting mark on your audience.",
    },
    {
      title: "Branding & Identity",
      pera: "Found an unbreakable connection with your audience through a noticeable and compelling brand identity. From business cards to social media graphics, we made a visual story that maintains a consistent and compelling presence across every interaction.",
    },
    {
      title: "Print Design",
      pera: "Step into the world of sensory experiences with our print design services. Our designers, masters of their craft, create visually arresting brochures, posters, flyers, and promotional materials that not only stand out but become tangible reflections of your brand essence.",
    },
    {
      title: "Web Design Graphics",
      pera: "Elevate your digital presence with a visual narrative that captivates and engages. Our web design graphics, spanning website elements, banners, and social media assets, are perfectly crafted to enhance your digital footprint, leaving a memorable mark on your online audience.",
    },
    {
      title: "Infographics",
      pera: "Transform complex information into an engaging visual symphony with our infographics. Ideal for presentations, reports, and marketing materials, our infographics weave data into a captivating narrative that resonates with your audience.",
    },
    {
      title: "Custom Illustrations",
      pera: "Infuse your projects with point to elegance through our custom illustrations. Our artists breathe life into your ideas, creating visuals that align seamlessly with your brand, providing a unique and memorable touch to your content.",
    },
  ];
  const faq_arr_graphics = [
    {
      H4: "What graphic design services do you offer?",
      pera: "Our graphic design services cover a broad spectrum to meet your diverse needs. From crafting eye-catching logos that represent your brand identity to developing comprehensive branding solutions, we also specialize in designing marketing collateral like brochures and business cards. Additionally, we create impactful social media graphics and provide website design elements for a strong online presence. Our goal is to visually articulate your brand's essence and messages with creativity and precision.",
    },
    {
      H4: "What is graphic design and why it is important for my business?",
      pera: "Graphic Design is like the art of using pictures and colours to tell your audience a story. It's the creative way of making your brand stand out and helping people understand what your brand is all about. Imagine it as the visual personality of your brand – the colours, pictures, and words all work together to create a message that people can easily see and remember.So, Graphic Design isn't just about making things look pretty; it's about making your brand memorable and instantly recognizable. It's like the face of your brand that speaks to people without words, helping them connect with what you're all about.",
    },
    {
      H4: "Can you help to create unique logo?",
      pera: "Absolutely! We're experts at making logos and crafting brand identities for all kinds of businesses. Think of a logo as your brand's signature – it's the special symbol that represents who you are. When we create brand identity, it's like giving your business a unique personality. We choose colours, fonts, and styles that make your brand stand out and be remembered. Whether you're just starting or looking to refresh your brand, our specialty is making sure your business looks awesome and leaves a lasting impression. So, if you need a logo that speaks volumes about your business, we've got you covered!",
    },
    {
      H4: "Can you design both print and digital media?",
      pera: "Certainly! Our skilled team is here to craft all kinds of designs, whether it's for the digital world like websites and social media or for things you can hold, like business cards, brochures, and banners. It's like having a team of creative experts ready to make your online and offline presence look fantastic. For digital stuff, we'll make your website not just functional but visually appealing, and your social media will pop with eye-catching graphics. On the print side, we'll design business cards that leave a lasting impression, brochures that tell your story beautifully, and banners that grab attention. Think of us as your go-to design expert, turning your ideas into visuals that stand out in both the online and real-world spaces. So, if you need designs that wow, we've got you covered! ",
    },
  ];

  return (
    <div className="bg-gray-100">
      <Helmet>
        <title>Best Graphic Design Agency in Ahmedabad</title>
        <meta
          name="description"
          content="Graphic designers utilize technical expertise and artistic skills to
          create visually appealing, functional, and effective designs for
          graphic design."
          data-rh="true"
        />
      </Helmet>
      <Header1
        img={"../img/new_graphic_design_header.png"}
        alt={"new_graphic_design_header"}
        heading={"BEST GRAPHIC DESIGN SERVICES"}
        pera={`"Our graphic designing team makes sure your needs are heard and fulfilled."`}
      />

      <div className="lg2:px-32 px-5 lg3:px-72 wow animate__animated animate__fadeInLeft">
        <div className="py-12 text-center text-xs md:text-lg">
          <h1 className="h1Classes">BEST GRAPHIC DESIGN AGENCY IN AHMEDABAD</h1>

          <p className="py-5 text-justify">
            In a digital age where first impressions matter more than ever, the visual identity of
            your brand plays a pivotal role in capturing the attention of your audience. Graphic
            design is the cornerstone of this visual identity, offering a powerful medium to
            communicate your message, establish brand recognition, and foster a lasting connection
            with your target audience. Here{" "}
            <NavLink to="/">
              {" "}
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                At, JK INFOSYS GLOBAL Graphic Design Company in Ahmedabad,
              </span>{" "}
            </NavLink>{" "}
            Provide top-notch Graphics Design Services, For Different Domains. Here We provide both
            digital and print media design according to your requirement.
          </p>
          <h2 className="py-5 text-blue-950 text-center text-2xl lg:text-3xl bebas-neue-regular">
            Elevate Your Brand with Our Unmatched Graphic Design Services
          </h2>

          <p className="py-5 text-justify">
            Welcome to JK INFOSYS GLOBAL, the pinnacle of design excellence where innovation meets
            imagination. Our Graphic Design Services go beyond the ordinary, offering you a world of
            visually stunning solutions crafted to elevate your brand to new heights. Immerse
            yourself in the artistry of design as we unfold a vast array of offerings tailored to
            meet your every creative need.
          </p>
          <div
            style={{ backgroundImage: "url('../img/Elevate_Your_Brand.png')" }}
            className="md:grid bg-cover py-10 text-white grid-cols-3 "
          >
            <div className="px-5">
              <img
                height="100%"
                width="100%"
                src="../img/Visual_Communication_Excellence.png"
                alt="Visual_Communication_Excellence"
              />
              <p className="py-5 text-justify">
                Graphic design goes beyond just aesthetics; it's about conveying a message
                effectively and leaving a lasting impact. Our graphic design services are crafted to
                ensure that your brand communicates with clarity, resonates with your audience, and
                distinguishes itself from the clutter.
              </p>
            </div>
            <div className="px-5">
              <img
                height="100%"
                width="100%"
                src="../img/Brand_ Consistency _and Recognition.png"
                alt="Brand_ Consistency _and Recognition"
              />
              <p className="py-5 text-justify">
                Consistency is key when it comes to branding. Our skilled graphic designers
                understand the importance of maintaining a cohesive visual language across all your
                marketing collateral. From logos to social media graphics, we ensure that every
                element aligns seamlessly with your brand identity, enhancing recognition and
                recall.
              </p>
            </div>
            <div className="px-5">
              <img
                height="100%"
                width="100%"
                src="../img/Versatility_in Design_Solutions.png"
                alt="Versatility_in Design_Solutions"
              />
              <p className="py-5 text-justify">
                Whether you are a startup seeking a bold and fresh identity or an established brand
                looking for a revamp, our graphic design services cater to a spectrum of needs. From
                print materials like brochures and business cards to digital assets such as web
                graphics and social media visuals, our versatile solutions are tailored to suit your
                unique requirements.
              </p>
            </div>
          </div>
          <Section
            arr={GraphicDesign_arr_1}
            title={"Unveiling the Spectrum of Our Graphic Design Services:"}
          />
          <div className="py-10">
            <h2 className="py-5 text-blue-950 text-center m text-2xl lg:text-3xl bebas-neue-regular">
              The Design Process
            </h2>
            <img
              height="100%"
              width="100%"
              className=" mx-auto"
              src="../img/design_process_image.png"
              alt="design_process_image"
            />
            <p className="py-5 text-justify">
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                1. Discovery and Consultation
              </span>{" "}
              We start by getting to know your brand – its values, target audience, and unique
              selling propositions. A thorough consultation helps us understand your goals and
              objectives, laying the foundation for a customized design strategy.
            </p>
            <p className="py-5 text-justify">
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                2. Conceptualization and Ideation
              </span>{" "}
              Once we have a clear understanding of your brand, our designers embark on the creative
              journey. Idea Planning, mood boards, and sketching help us explore various design
              concepts that align with your brand identity.
            </p>
            <p className="py-5 text-justify">
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                3. Design Development
              </span>{" "}
              With a solid concept in place, we move on to the design development phase. This is
              where your brand comes to life visually. We pay attention to every detail, ensuring
              that the design not only looks impressive but also effectively communicates your
              message.
            </p>
            <p className="py-5 text-justify">
              {" "}
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                4. Feedback and Revisions
              </span>{" "}
              Your input is invaluable to us. We present the initial designs for your feedback and
              make revisions based on your suggestions. This process continues until we achieve a
              design that not only meets but exceeds your expectations.
            </p>
            <p className="py-5 text-justify">
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                5. Finalization and Delivery
              </span>{" "}
              Once the design has received your approval, we finalize the files for various
              applications – be it for print, web, or social media. Our delivery includes all the
              necessary files and formats, ensuring a smooth implementation of the design across
              different platforms.
            </p>
          </div>

          <div>
            <h2 className="py-5 text-blue-950 text-center text-2xl lg:text-3xl bebas-neue-regular">
              Why Choose for Your Design Journey?
            </h2>

            <div className="grid  md:grid-cols-2 lg:grid-cols-4 gap-4">
              <div className="border-4  py-5 shadow-blue-200 shadow-lg rounded-2xl px-4">
                <img
                  height="100%"
                  width="50"
                  className="   inline-block"
                  src="../img/creativity_ceyond conventions.png"
                  alt="creativity_ceyond conventions"
                />
                <p className="py-5 ">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Creativity Beyond Conventions :
                  </span>{" "}
                  Our team is dedicated to pushing creative boundaries, ensuring each design is not
                  just a product but a masterpiece in its own right.
                </p>
              </div>
              <div className="border-4 py-5   shadow-amber-200 shadow-lg rounded-2xl px-4">
                <img
                  height="100%"
                  width="50"
                  className=" inline-block"
                  src="../img/collaborative_ excellence.png"
                  alt="collaborative_ excellence"
                />
                <p className="py-5 ">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Collaborative Excellence :
                  </span>{" "}
                  We believe in the power of collaboration, working hand-in-hand with our clients to
                  not just meet but exceed their design expectations.
                </p>
              </div>
              <div className="border-4 py-5 shadow-blue-200 shadow-lg rounded-2xl px-4">
                <img
                  height="100%"
                  width="50"
                  className=" inline-block"
                  src="../img/versatility_across mediums.png"
                  alt="versatility_across mediums"
                />
                <p className="py-5 ">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Versatility Across Mediums:
                  </span>{" "}
                  Digital or print, our designers possess the skills to cater to diverse design
                  needs, ensuring a comprehensive solution for your unique requirements.
                </p>
              </div>
              <div className="border-4 py-5 shadow-amber-200 shadow-lg rounded-2xl px-4">
                <img
                  height="100%"
                  width="50"
                  className=" inline-block"
                  src="../img/quality_ assurance.png"
                  alt="quality_ assurance"
                />
                <p className="py-5 ">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Quality Assurance :
                  </span>{" "}
                  Committed to delivering excellence, we guarantee designs that not only meet but
                  surpass your expectations, setting new benchmarks for quality.
                </p>
              </div>

              {/* <p className="py-5 text-justify">
                Ready to embark on a transformative design journey? Contact us
                today, and let's craft a visual narrative that not only reflects
                your brand but propels it into a realm of unparalleled success!
              </p> */}
            </div>
          </div>

          {/* <div className=" py-10">
          <Section
            arr={GraphicDesign_arr_2}
            title={"WHY CHOOSE OUR GRAPHIC DESIGN SERVICES?"}
          />
        </div> */}
        </div>
        <Faq_component
          arr={faq_arr_graphics}
          pera={"FREQUENTLY ASKED QUESTIONS ABOUT GRAPHIC DESIGNING SERVICES"}
        />
      </div>
    </div>
  );
};

export default GraphicDesign;
