import React from "react";
import { Link } from "react-router-dom";
import Card from "./Card";
import { NavLink } from "react-router-dom";
import scrollHook from "../Hooks/scrollHook";

const Section = ({ arr, title }) => {
  const arr1 = arr;
  return (
    <div>
      <div className=" py-5 px-10 content-center   wow animate__animated animate__fadeInLeft">
        <h2 className=" h2Classes ">{title}</h2>

        <div className="py-4 text-center">
          <Link to="/contact-us">
            <a class="relative inline-flex items-center justify-center px-10 py-4 overflow-hidden   tracking-tighter text-white bg-gray-800 rounded-lg group">
              <span class="absolute w-0 h-0 transition-all duration-500 ease-out bg-yellow-400 rounded-full group-hover:w-56 group-hover:h-56"></span>
              <span class="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
              <span class="relative">INQUIRE NOW</span>
            </a>
          </Link>
        </div>
      </div>
      <div className="grid   lg:grid-cols-3">
        {arr1.map((e, i) => (
          <NavLink
            className="cols-span-1 select-none"
            to={e.link}
            onClick={scrollHook}
          >
            <div>
              <Card i={i} title={e.title} pera={e.pera} />
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
export default Section;
