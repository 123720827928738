import React from "react";

const Card = ({ i, title, pera }) => {
  return (
    <div className="  ">
      <div
        className={` my-2 border-2 rounded-3xl    md:mx-3 shadow-2xl  ${
          i % 2 === 0
            ? "border-blue-950 shadow-blue-100"
            : " border-yellow-400  shadow-yellow-100"
        }   wow animate__animated animate__fadeInLeft`}
      >
        <div className="md:flex   ">
          <h3
            className={` w-full rounded-t-2xl  ${
              i % 2 === 0
                ? "bg-blue-950 text-white"
                : " bg-yellow-400  text-blue-950"
            }  text-2xl bebas-neue-regular  md:text-xl py-5 px-5`}
          >
            {title}
          </h3>
        </div>
        <p className=" py-5 px-5 md:h-80 leading-8 ">{pera}</p>
      </div>
    </div>
  );
};

{
  /* <div
className={` my-2 border-2 rounded-3xl    md:mx-3 shadow-2xl  ${
  i % 2 === 0
    ? "border-blue-950 shadow-blue-100"
    : " border-yellow-400  shadow-yellow-100"
}   wow animate__animated animate__fadeInLeft`}
>
<div
  className={`md:flex px-5  rounded-t-2xl  ${
    i % 2 === 0
      ? "bg-blue-950 text-white"
      : " bg-yellow-400  text-blue-950"
  }  `}
> */
}
export default Card;
