import React from "react";
import "../home.css";
import scrollHook from "../Hooks/scrollHook";

import { NavLink } from "react-router-dom";

const Services_sections = ({ imgUrl, imgAlt, title, linkToServices,label }) => {
  return (
    <div>
      <NavLink to={linkToServices} onClick={scrollHook} aria-label={label}>
        <div class="box">
          <img
            class="desktop lazy"
            src={imgUrl}
            alt={imgAlt}
            width="475"
            height="454"
          />
          <div class="box-content">
            <div class="content">
              <p className=" text-sm title">{title}</p>
              <div class="post"></div>
              <ul class="icon">
                <li>
                  <a>READ MORE</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default Services_sections;
