import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { GrServices } from "react-icons/gr";
import Social from "./Social";
import Navbutton from "./Navbutton";
const MobileNav = ({
  nav,
  handleNav,
  arr_NAV,
  arr_NAV1,
  arr_NAV2,
  // arr_NAV3,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <div>
      <div className="w-full  lg:hidden ">
        <div
          className={
            nav
              ? ` bg-slate-50  z-50 px-5 fixed  top-0 left-0 w-[90%] h-full text-lg  ease-in-out duration-500`
              : "ease-in-out duration-500 fixed left-[-100%]"
          }
        >
          {/* <div className={`   ${isSidebarOpen1 ? 'left-[-100%] ':'left-0'  }`}> */}
          <ul className="divide-y-2    divide-gray-200">
            <div className="flex py-5 justify-between">
              <Social />
              <Navbutton size1={20} nav={nav} handleNav={handleNav} size2={0} />
            </div>

            {arr_NAV.map((e) => (
              <li className=" py-4" onClick={handleNav}>
                <Link
                  to={e.link}
                  className="hover:text-blue-950 font-semibold flex gap-4 duration-300  hover:scale-95 leading-2 cursor-pointer transform transition-transform"
                >
                  {e.icon}
                  {e.name}
                </Link>
              </li>
            ))}
            {/* <div>
              <NavLink to="/faq">FAQ</NavLink>
            </div> */}
            <li className=" py-2">
              <div className="flex">
                <NavLink
                  to="/services"
                  // onClick={handleNav}
                  className="hover:text-blue-950 font-semibold ease-out duration-300 transform transition-transform hover:scale-95  flex gap-4 leading-2 cursor-pointer"
                >
                  <div className="flex gap-4">
                    <GrServices size={30} />
                    Services
                  </div>
                </NavLink>
                <button
                  className=" hover:text-blue-950 transition"
                  onClick={toggleSidebar}
                >
                  {" "}
                  &nbsp; <space className=" text-xs">▼</space>{" "}
                </button>
              </div>
              <div className={`   ${isSidebarOpen ? "block" : "hidden"}`}>
                <ul className="block w-full    ">
                  {arr_NAV1.map((e) => (
                    <li className="py-2" onClick={handleNav}>
                      <Link
                        to={e.link}
                        className="hover:text-blue-950 font-semibold ease-out duration-300 transform transition-transform hover:scale-95  flex gap-4 leading-2 cursor-pointer"
                      >
                        {" "}
                        {e.icon}
                        {e.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            {arr_NAV2.map((e) => (
              <li className=" py-4" onClick={handleNav}>
                <Link
                  to={e.link}
                  className="hover:text-blue-950 font-semibold ease-out duration-300 transform transition-transform hover:scale-95  flex gap-4 leading-2 cursor-pointer "
                >
                  {e.icon}
                  {e.name}
                </Link>
              </li>
            ))}
          </ul>

          <div onClick={handleNav} className="flex space-x-4">
            <a
              href="tel:+919081081005"
              className="hover:text-blue-950 leading-2 my-auto text-lg gap-2 flex font-semibold cursor-pointer transform transition-transform hover:scale-90 "
            >
              Call
              <IoCallOutline size={32} className=" font-bold  " />{" "}
            </a>

            <Link
              onClick={() =>
                (window.location = "mailto:jkinfosysgloble@gmail.com")
              }
              className="hover:text-blue-950 leading-2 my-auto text-lg flex gap-2 font-semibold cursor-pointer transform transition-transform hover:scale-90"
            >
              Email <AiOutlineMail size={32} className="font-bold " />
            </Link>
          </div>
          <Link to="/" onClick={handleNav}>
            <img
              className=" w-40 py-5  lg:w-32 lg2:w-40 "
              src="../img/logo.png"
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
