import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { GrServices } from "react-icons/gr";
import Social from "./Social";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import Navbutton from "./Navbutton";
import MobileNav from "./MobileNav";

const Nav = ({ arr_NAV, arr_NAV1, arr_NAV2, arr_NAV3 }) => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div>
      <nav className="flex items-center px-10  bg-top bg-cover  justify-between">
        {/* <!-- Brand/logo --> */}
        <Link to="/">
          <img
            className=" w-40    lg2:w-56 hover:shadow-xl py-3   text-xs font-medium uppercase leading-normal text-white shadow-sm duration-500 transition   ease-in-out    focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg "
            src="../img/logo.png"
            alt=""
          />
        </Link>

        <MobileNav
          nav={nav}
          handleNav={handleNav}
          arr_NAV={arr_NAV}
          arr_NAV1={arr_NAV1}
          arr_NAV2={arr_NAV2}
          arr_NAV3={arr_NAV3}
        />

        <ul className="hidden mobile-menu z-50   lg:flex space-x-4">
          {arr_NAV.map((e) => (
            <li className=" gap-3" onClick={handleNav}>
              <Link
                to={e.link}
                className="hover:text-blue-950  flex items-center gap-2   leading-2  cursor-pointer transform transition-transform hover:scale-110 hover:shadow-2xl  p-1 text-xs font-medium uppercase leading-normal  shadow-sm duration-500    ease-in-out    focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg "
              >
                {e.icon}
                {e.name}
              </Link>
            </li>
          ))}
          <li className="dropdown inline   cursor-pointer  text-base  tracking-wide">
            <NavLink
              to="/services"
              className="hover:text-blue-950 flex gap-2  leading-2    items-center    leading-2  cursor-pointer transform transition-transform hover:scale-110 hover:shadow-2xl  p-1 text-xs font-medium uppercase leading-normal  shadow-sm duration-500    ease-in-out    focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <div className=" flex items-center gap-1">
                <GrServices size={30} /> Services ▼ &nbsp;
              </div>
              {/* <space className=" text-xs">▼</space>{" "} */}
            </NavLink>
            <div className="dropdown-menu absolute hidden h-auto  pt-4">
              <ul className="block w-full bg-white shadow px-5 py-2">
                {arr_NAV1.map((e) => (
                  <li className=" py-2 " onClick={handleNav}>
                    <Link
                      to={e.link}
                      className="  leading-2 flex  gap-4   hover:shadow-2xl  p-3 text-xs font-medium uppercase leading-normal  shadow-sm duration-500    ease-in-out    focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
                    >
                      {e.icon}

                      {e.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          {arr_NAV2.map((e) => (
            <li className=" gap-3" onClick={handleNav}>
              <Link
                to={e.link}
                className="hover:text-blue-950  flex items-center gap-2   leading-2  cursor-pointer transform transition-transform hover:scale-110 hover:shadow-2xl  p-1 text-xs font-medium uppercase leading-normal  shadow-sm duration-500    ease-in-out    focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg "
              >
                {e.icon}
                {e.name}
              </Link>
            </li>
          ))}
        </ul>

        <div className="hidden mobile-menu lg:flex space-x-4">
          <a
            href="tel:+919081081005"
            className="hover:text-blue-950 leading-2 my-auto text-lg gap-2 flex font-semibold cursor-pointer transform transition-transform hover:scale-90 "
            aria-label="tel:+919081081005"
          >
            <IoCallOutline size={32} className=" font-bold  " />{" "}
          </a>
          <Link
            onClick={() =>
              (window.location = "mailto:jkinfosysgloble@gmail.com")
            }
            className="hover:text-blue-950  leading-2 my-auto text-sm lg2:text-lg flex gap-2 font-bold cursor-pointer transform transition-transform hover:scale-110"
          >
            <AiOutlineMail size={30} className="lg2:font-bold " />
          </Link>

          <Social />
        </div>
        <div className="flex items-center justify-between lg:hidden  py-10 ">
          <div>
            <Navbutton size1={20} handleNav={handleNav} size2={20} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
