import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Portfolio = () => {
  const Portfolio_website_arr = [
    {
      image: "../img/portfolio4.png",
      alt: "portfolio4",
      dr: "https://unionmotors.in/",
      view: "w-full ",
    },
    // {
    //   image: "../img/CM-INFOTECH-Wealt :"CM"-,aim-for-the-best.png",
    //   dr: "https://www.cminfotech.in/",
    //   view: "w-full ",
    // },
    {
      image: "../img/JIWO-Ahmedabad-Chapter-Facebook.png",
      alt: "JIWO-Ahmedabad-Chapter-Facebook",
      dr: "https://www.facebook.com/jiwoAC/",
      view: "w-full",
    },
    {
      image: "../img/portfolio7.png",
      alt: "portfolio7",
      dr: "https://amulyakriya.com/",
      view: "w-full ",
    },
    {
      image: "../img/portfolio8.png",
      alt: "portfolio8",
      dr: "https://prs-recruitment.com/",
      view: "w-full ",
    },
    {
      image: "../img/portfolio9.png",
      alt: "portfolio9",
      dr: "https://amsltdgroup.com/",
      view: "w-full ",
    },

    { image: "../img/portfolio2.png", alt: "portfolio2", dr: "#", view: "w-full " },
    { image: "../img/Portfolio3.png", alt: "Portfolio3", dr: "#", view: "w-full " },

    { image: "../img/nine_portfolio.png", alt: "nine_portfolio", dr: "#", view: "w-full " },
    { image: "../img/ten_portfolio.png", alt: "ten_portfolio", dr: "#", view: "w-full " },
    { image: "../img/eleven_portfolio.png", alt: "eleven_portfolio", dr: "#", view: "w-full " },

    { image: "../img/twelve_portfolio.png", alt: "twelve_portfolio", dr: "#", view: "w-full " },
    { image: "../img/thirteen_portfolio.png", alt: "thirteen_portfolio", dr: "#", view: "w-full " },
  ];

  const social_media_arr = [
    {
      image: "../img/portfolio_social_one.jpg",
      alt: "portfolio_social_one",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_two.jpg",
      alt: "portfolio_social_two",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_three.jpg",
      alt: "portfolio_social_three",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_four.jpg",
      alt: "portfolio_social_four",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_five.jpg",
      alt: "portfolio_social_five",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_six.jpg",
      alt: "portfolio_social_six",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/porfolio_social_seven.jpg",
      alt: "porfolio_social_seven",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_eight.jpg",
      alt: "portfolio_social_eight",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_nine.jpg",
      alt: "portfolio_social_nine",
      dr: "#",
      view: "w-full ",
    },
    {
      image: "../img/portfolio_social_ten.jpg",
      alt: "portfolio_social_ten",
      dr: "#",
      view: "w-full ",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Jk Infosys Global Portfolio</title>
        <meta
          name="description"
          content="Welcome to portfolio! we are passionate about creating digital experiences that blend innovation, creativity, and functionality."
          data-rh="true"
        />
      </Helmet>

      <div className="  text-center  py-16 ">
        <p className="py-5  text-3xl lg:text-5xl bebas-neue-regular text-blue-950">OUR PORTFOLIO</p>
        <p className="lg2:px-56 text-blue-900 text-xl  text-center font-semibold lg:px-32 px-5">
          Welcome to our portfolio! we are passionate about creating digital experiences that blends
          innovation, creativity, and functionality. With a strong foundation in Digital Marketing,
          website development,E-Commerce Website Development, Graphic Designing , Social Media
          Marketing and Branding. We've privilege of working on a diverse range of projects,And We
          Maintains creativity with diversity.
        </p>
      </div>

      <h2 className="py-5  text-xl lg:text-3xl bebas-neue-regular text-blue-950 flex justify-center underline">
        {" "}
        OUR WEBSITE DEVELOPMENT PORTFOLIO
      </h2>
      <div className="grid md:grid-cols-2 lg2:px-32 lg3:px-72   lg:grid-cols-3  py-5 ">
        {Portfolio_website_arr.map((e) => (
          <Link to={e.dr} target="_blank">
            <div className="cols-span-1 mx-5 my-5 hover:shadow-2xl border-2  md:border-8 px-5 py-5">
              <img className={e.view} src={e.image} alt={e.alt} height="100%" width="100%" />
            </div>
          </Link>
        ))}
      </div>

      <h2 className="py-5  text-xl lg:text-3xl bebas-neue-regular text-blue-950 flex justify-center underline">
        {" "}
        OUR SOCIAL MEDIA PORTFOLIO
      </h2>
      <div className="grid md:grid-cols-2 lg2:px-32 lg3:px-72  lg:grid-cols-3   py-5 ">
        {social_media_arr.map((e) => (
          <Link to={e.dr} target="_blank">
            <div className="cols-span-1 mx-5 my-5 hover:shadow-2xl border-2  md:border-8 px-5 py-5">
              <img className={e.view} src={e.image} alt={e.alt} height="100%" width="100%" />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
