import React from "react";
import { NavLink } from "react-router-dom";
import scrollHook from "../Hooks/scrollHook";

const Homesection = ({ arr, title }) => {
  return (
    <div>
      <div className=" py-5 md:px-10 content-center   wow animate__animated animate__fadeInLeft">
        <h2 className="text-4xl bebas-neue-regular text-blue-950 text-center py-2   underline underline-offset-8 ">
          {title}
        </h2>
      </div>
      <div className="grid   lg:grid-cols-3">
        {arr.map((e, i) => (
          <NavLink to={e.link} onClick={scrollHook} aria-label={e.label}>
            <div
              className={` my-2 border-2 rounded-3xl    md:mx-3 shadow-2xl  ${
                i % 2 === 0
                  ? "border-blue-950 shadow-blue-100"
                  : " border-yellow-400  shadow-yellow-100"
              }   wow animate__animated animate__fadeInLeft`}
            >
              <div
                className={`md:flex px-5  rounded-t-2xl  ${
                  i % 2 === 0
                    ? "bg-blue-950 text-white"
                    : " bg-yellow-400  text-blue-950"
                }  `}
              >
                <h3 className={`w-full  bebas-neue-regular text-2xl py-5`}>
                  {i + 1} - {e.title}
                </h3>
              </div>
              <p className="text-base px-5   py-2  md:h-64  leading-8 ">
                {e.pera}
              </p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Homesection;
