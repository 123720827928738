import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import scrollHook from "../Hooks/scrollHook";
import SliderClick from "../Component/sliderClick";
import "../animations.css";
const About = () => {
  const whatWeDo = {
    backgroundImage: "url('../img/BgImg_what_we_do.png')",
  };

  // const five_boxs = {
  //   backgroundImage: "url('../img/page_5_boxes.png')",
  // };

  const svgContent = `
    <svg fill="#000000" width="24px" height="24px" viewBox="0 0 24 24" id="triple-right-sign" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" class="icon line-color">
      <polyline id="secondary" points="8.5 19 15.5 12 8.5 5" style="fill: none; stroke: rgb(44, 169, 188); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline>
      <polyline id="primary" points="14 5 21 12 14 19" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline>
      <polyline id="primary-2" data-name="primary" points="3 19 10 12 3 5" style="fill: none; stroke: rgb(255, 255, 255); stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></polyline>
    </svg>
  `;

  const servicesSVG = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#001d4a" class="w-12 h-12">
  <path stroke-linecap="round" stroke-linejoin="round" d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" transform="rotate(90 12 12)" />
</svg>
`;

  const paragraphs = [
    "Make your brand to the top of search results with our SEO expertise.",
    "Utilize cutting-edge algorithms and stick to your industry-leading practices to enhance visibility to the fullest extent.",
    "Attract qualified leads and increase your online presence.",
  ];
  const paragraph2 = [
    "Here We Build and Design websites that brings results.",
    "Crafted on versatile technologies like React-JS, Node-JS, WordPress and Trending Web Technologies.",
    "We craft bespoke digital experiences, blending your vision with the creative spark whether it is E-Commerce or a branding site.",
  ];

  const paragraph3 = [
    "Navigate the ever-evolving social media landscape with our expert guidance.",
    "Run targeted campaigns and authentic storytelling to build meaningful connections.",
    "Enhance brand visibility and engage with your customers effectively.",
  ];
  const paragraph4 = [
    "Utilize data-driven insights to craft hyper-targeted paid advertising campaigns.",
    "Maximize your return on investment with carefully curated and optimized ads.",
    "Drive conversions and amplify your brand's reach.",
  ];
  const paragraph5 = [
    "Craft attractive content that engages your audience.",
    "From effective copywriting to visually stunning visuals, we create content that resonates.",
    "Build brand loyalty and keep your audience engaged.",
  ];
  const paragraph6 = [
    "Be open and clear by giving practical insights from the data.",
    "Make informed decisions based on comprehensive analytics.",
    "Track and measure your digital success with clarity.",
  ];

  return (
    <div>
      <Helmet>
        <title>Jk Infosys Global About Us</title>
        <meta
          name="description"
          content="An Information Technology Company of
          passionate professionals who have come together to help you
          excel in your business."
          data-rh="true"
        />
      </Helmet>

      <div className=" wow animate__animated animate__fadeInLeft ">
        <div className="pb-12 text-center text-xs  md:text-lg">
          {/* ***********************************        header ************************************************ */}
          <div
            className=" p-5 bg-blue-950 md:p-10 "
            style={{
              backgroundImage: "url('../img/about_page_services_bg.png')",
            }}
          >
            <div className="md:grid grid-cols-4 grid-rows-4 p-5 gap-5 ">
              <div className=" hidden md:block  bg-slate-200   md:p-3 rounded-xl">
                <NavLink to="/" onClick={scrollHook}>
                  <img height={100} width={"100%"} src="../img/jk_main.png" alt="jk_main" />
                </NavLink>
              </div>

              <NavLink
                className="col-start-2 row-span-2 bg-slate-200  rounded-xl  flex flex-col justify-between items-center py-5 my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/digital-marketing"
              >
                <img
                  className=" "
                  height={100}
                  width={150}
                  src=" ../img/Digital_Marketing_icon.png"
                  alt="Digital_Marketing_icon"
                />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl   ">DIGITAL MARKETING</p>
              </NavLink>

              <NavLink
                className="col-start-3 row-span-2 bg-slate-200  rounded-xl flex flex-col justify-between items-center py-5  my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/social-media-marketing"
              >
                <img
                  height={100}
                  width={150}
                  src=" ../img/social_media_icon.png"
                  alt="social_media_icon"
                />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl    ">
                  SOCIAL MEDIA MARKETING
                </p>
              </NavLink>

              <NavLink
                className="col-start-4 row-span-2 bg-slate-200   rounded-xl flex flex-col justify-between items-center py-5 my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/seo"
              >
                <img height={100} width={150} src=" ../img/seo_icon.png" alt="seo_icon" />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl    "> SEO</p>
              </NavLink>

              <NavLink
                className=" hidden md:flex col-1 row-start-2 row-span-6 bg-slate-200 md:p-5 rounded-xl  flex-col justify-center items-center py-5 my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/services"
              >
                <p className="font-bold text-blue-950 text-xl lg:text-3xl   "> SERVICES</p>
                <span dangerouslySetInnerHTML={{ __html: servicesSVG }} />
              </NavLink>

              <NavLink
                className="col-2 row-start-3 row-span-5 bg-slate-200 md:py-10 py-5 rounded-xl flex flex-col justify-between items-center  my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/web-development"
              >
                <img
                  height={100}
                  width={150}
                  src=" ../img/website_development_icon.png"
                  alt="website_development_icon"
                />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl   ">
                  {" "}
                  WEBSITE DEVELOPMENT
                </p>
              </NavLink>

              <NavLink
                className="col-3 row-start-3 row-span-5 bg-slate-200 md:py-10 py-5 rounded-xl flex flex-col justify-between items-center  my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/graphic-design"
              >
                <img
                  height={100}
                  width={150}
                  src=" ../img/Graphic_Design_icon.png"
                  alt="Graphic_Design_icon"
                />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl   "> GRAPHIC DESIGNING</p>
              </NavLink>

              <NavLink
                className="col-4 row-start-3 row-span-5 bg-slate-200 md:py-10  py-5 rounded-xl flex flex-col justify-between items-center   my-2 md:my-0 hover:bg-gradient-to-r  from-red-200   from-20%  via-blue-200 via-70%  to-blue-100 to-90% transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                to="/branding"
              >
                <img height={100} width={150} src=" ../img/brand_icon.png" alt="brand_icon" />
                <p className="font-bold text-blue-950 text-xl lg:text-2xl    "> BRANDING </p>
              </NavLink>
            </div>
          </div>

          {/* *****************************************************about us section starts here ******************************************************************************** */}

          <div className=" lg2:px-32 px-5 lg3:px-72 md:grid grid-cols-2 gap-4  ">
            <div className="lg:py-10 text-blue-950">
              <h1 className="font-bold text-3xl lg:text-5xl ">WHAT IS JK INFOSYS GLOBAL</h1>
              <p className="py-5 text-justify">
                JK Infosys Global is your go-to IT Company, powered by a team of passionate
                professionals. We're here to make your business shine. With the right resources and
                dedication, we turn every project into a success story. We aim to create digital
                experiences that connect with users, making your online presence delightful and your
                digital platform a growth hub.
              </p>

              <p className="py-5 text-justify">
                JK INFOSYS GLOBAL offers a comprehensive suite of digital marketing and web-development
                services to elevate your brand, engage your audience, and drive measurable results.
                Our tailored strategies, creative prowess, and data-driven approach ensure that your
                digital endeavors are effective and uniquely crafted to meet your specific goals.
              </p>
            </div>

            <div>
              <img
                src="../img/about_us_main_photo.png"
                alt="about_us_main_photo"
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>

          {/* ********************** what we sections starts********************** */}

          <div className=" lg2:px-32 px-5 lg3:px-72">
            <div className="" style={whatWeDo}>
              <h2 className="text-white py-3 font-bold text-3xl lg:text-5xl ">WHAT WE DO </h2>
              <div className="md:grid grid-cols-2 gap-6 p-6  ">
                <div class="border-2 border-white border-solid text-white md:text-justify py-4  px-2 ">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">
                    1. Search Engine Optimization (SEO):
                  </h3>
                  {paragraphs.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div class="border-2 border-white border-solid  text-white py-4 md:text-justify px-2">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">
                    2. Website Design and Development:
                  </h3>
                  {paragraph2.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div class="border-2 border-white border-solid  text-white py-4 md:text-justify px-2">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">
                    3. Social Media Management:
                  </h3>
                  {paragraph3.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div class="border-2 border-white border-solid  text-white py-4 md:text-justify px-2">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">4. Paid Advertising:</h3>
                  {paragraph4.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div class="border-2 border-white border-solid  text-white py-4  md:text-justify px-2">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">5. Content Creation:</h3>
                  {paragraph5.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div class="border-2 border-white border-solid text-white py-4  md:text-justify px-2">
                  <h3 className="font-bold py-2 text-xl lg:text-2xl">
                    6. Analytics and Reporting:
                  </h3>
                  {paragraph6.map((paragraph, index) => (
                    <p key={index} style={{ display: "flex" }} className="my-1 text-justify">
                      <span
                        dangerouslySetInnerHTML={{ __html: svgContent }}
                        style={{ marginRight: "1%" }}
                      />
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* ********************** what we section ends here ********************** */}

          <div className="lg2:px-32 px-5 lg3:px-72 py-10">
            <div className=" py-10 ">
              <h2 className="text-blue-950 text-center  py-3 font-bold text-3xl lg:text-5xl">
                What We're Great At:
              </h2>

              <div className="px-5 md:grid grid-cols-3 gap-5 pt-10 ">
                <img
                  src="../img/Being_social_media_pros.png"
                  alt="Being_social_media_pros"
                  height={"100%"}
                  width={"100%"}
                />
                <img
                  src="../img/creating_awesome_content.png"
                  alt="creating_awesome_content"
                  height={"100%"}
                  width={"100%"}
                />
                <img
                  src="../img/getting_found_online.png"
                  alt="getting_found_online"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
              <div className="md:px-48 px-5 md:flex justify-center  gap-10">
                <div>
                  {" "}
                  <img
                    src="../img/giving-you_insignful_number.png"
                    alt="you_insignful_number"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
                <div>
                  <img
                    src="../img/smart_ads_that_work.png"
                    alt="smart_ads_that_work"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>

            <div className="  border-blue-950  md:border-2 ">
              <h2 className="text-blue-950 py-3 text-center md:py-4 font-bold text-3xl lg:text-5xl">
                {" "}
                How We Do
              </h2>
              <div className="lg:grid  grid-cols-7   text-base">
                <div className=" col-span-4  text-blue-950  px-4">
                  <div className=" grid grid-rows-2 md:grid-cols-2 py-5  gap-4">
                    <div className="  border-dashed border-blue-950 p-4   border-2 ">
                      <div className="bg-orange-500 font-bold p-3 mx-5 my-2 md:mx-10 rounded-xl">
                        Team work is the key
                      </div>
                      <p>
                        We work closely with you to understand your goals, who you're trying to
                        reach, and what your brand stands for. We keep you updated regularly so
                        you're always in the know
                      </p>
                    </div>

                    <div className="  border-dashed border-blue-950 p-4   border-2 ">
                      <div className="bg-orange-500 font-bold p-3 mx-5 my-2 md:mx-10 rounded-xl">
                        Data Guides Our Choices:
                      </div>
                      <p>
                        Instead of guessing, we use data to make smart decisions. We look at the
                        numbers to find opportunities, improve how things are going, and get the
                        best results.
                      </p>
                    </div>
                    <div className="  border-dashed border-blue-950 p-4   border-2 ">
                      <div className="bg-orange-500 font-bold p-3 mx-5 my-2 md:mx-10 rounded-xl">
                        Adding a Creative Touch:
                      </div>
                      <p>
                        We don't just follow the rules; we add a bit of creative magic to our
                        campaigns. Our goal is to make your brand not just fit in but stand out.
                      </p>
                    </div>
                    <div className="  border-dashed border-blue-950 p-4   border-2 ">
                      <div className="bg-orange-500 font-bold p-3 mx-5 my-2 md:mx-10 rounded-xl">
                        Focused on Real Results:
                      </div>
                      <p>
                        We're not just after likes and clicks. Our main focus is on getting you
                        real, measurable results that match exactly what you want for your business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-3 ">
                  <img
                    className=" m-auto "
                    src="../img/how_we_do_img.png"
                    alt="how_we_do_img"
                    height={"100%"}
                    width={"100%"}
                  />
                </div>
              </div>
            </div>
          </div>

          <SliderClick />
        </div>
      </div>
    </div>
  );
};

export default About;
