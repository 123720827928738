import React from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

const Navbutton = ({ size1, nav, handleNav, size2 }) => {
  return (
    <div>
      <div
        onClick={handleNav}
        className="  px-3 py-3  flex   bg-blue-950  text-white  justify-end lg:hidden "
      >
        {nav ? <AiOutlineClose size={size1} /> : <AiOutlineMenu size={size2} />}
      </div>
    </div>
  );
};

export default Navbutton;
