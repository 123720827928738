import React from "react";
import Social from "./Social";
import { Link } from "react-router-dom";
import ContactForm from "../Component/ContactForm";
import { NavLink } from "react-router-dom";

const Footer = ({ arr_NAV1, arr_NAV3 }) => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  // style={{ backgroundImage: `url(${"../img/Cover.jpg"})`  bg-cover bg-bottom contrast-100 }}
  return (
    <div>
      <ContactForm />

      <footer className="  text-white">
        <div className="grid bg-blue-950   md:grid-cols-2 lg:grid-cols-4     py-4 px-5 lg:py-8   ">
          <div className="col-span-1   lg:text-left">
            <p className=" font-bold text-2xl py-5">Who are we?</p>
            <p className="text-lg ">
              JK INFOSYS GLOBAL is your go-to digital marketing company in Ahmedabad. We offer a
              range of services, including digital marketing, website development and design,
              branding, social media marketing, graphic design and SEO. Our dedicated team of
              digital experts is here to make your brand stand out in the online world.
            </p>
          </div>
          <div className="col-span-1  lg:text-left">
            <p className=" font-bold text-2xl py-5">Our Services</p>
            <ul className="text-lg ">
              {arr_NAV1.map((e) => (
                <li className="py-1 ">
                  <Link
                    to={e.link}
                    className="hover:text-white flex gap-3 scale-90 items-center leading-2 cursor-pointer transform transition-transform hover:scale-100"
                    onClick={scrollToTop}
                  >
                    {e.icon}
                    {e.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-1 lg:px-10  lg:text-left">
            <p className=" font-bold text-2xl py-5">Quick Links</p>
            <div className="text-lg  ">
              {/* {arr_NAV3.map((e) => (
                <div>
                  <NavLink to={e.link} onClick={scrollToTop}>
                    {e.name}
                  </NavLink>
                </div>
              ))} */}
              <div>
                <NavLink to="/privacy_policy" onClick={scrollToTop}>
                  Privacy Policy
                </NavLink>
              </div>
              <div>
                <NavLink to="/faq" onClick={scrollToTop}>
                  FAQ
                </NavLink>
              </div>
            </div>
          </div>

          <div className=" inline-block">
            <Link to="/contact-us" onClick={scrollToTop}>
              <div className="col-span-1  lg:text-left">
                <p className=" font-bold text-2xl py-5">Get in touch</p>
                <p className="text-lg  pb-5">Call Us: +91 9081081005 / 06</p>
              </div>
              {/* <p className=" font-bold text-2xl py-5">About</p>

            <p className="text-lg ">
              JK Infosys Global offers affordable Software Development,
              Application Development for businesses.
            </p> */}
            </Link>
            <Social />
          </div>
        </div>

        <div className=" lg:grid  text-blue-950  grid-cols-2  py-5 grid-rows-1">
          <div className="lg:ml-72 text-center ">
            <p>Copyright © 2024 JK Infosys Global</p>
          </div>
          <div className="lg:mr-72 text-center">
            <p>Powered By JK Infosys Global</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
