import React from "react";
import DigitalProcess from "../Component/DigitalProcess";
import useCounter from "../Hooks/useCounter";

import "simple-reveal/index.css";
import "../animations.css";
import "../home.css";
import { Link } from "react-router-dom";
import Homesection from "../Component/Homesection";
import { Helmet } from "react-helmet-async";
import Services_sections from "../Component/Services_sections";
import scrollHook from "../Hooks/scrollHook";
import Faq_component from "../Component/faq";
import Popups from "../Component/Popups";

const Home = () => {
  const { count, sectionCount, isSectionVisible } = useCounter();

  const home_arr_1 = [
    {
      link: "/digital-marketing",
      name: "Digital Marketing",
      imgUrl: "../img/digitalmarketing.png",
      imgAlt: "Digital Marketing image",
      label: "Move to Digital Marketing services Page",
    },
    {
      link: "/web-development",
      name: "Web Development",
      imgUrl: "../img/webdevlopment.png",
      imgAlt: "Web Development image",
      label: "Move to Web Development services Page",
    },
    {
      link: "/social-media-marketing",
      name: "Social Media Marketing",
      imgUrl: "../img/socialmediamarketing.png",
      imgAlt: "Social Media Marketing image",
      label: "Move to Social Media Marketing services Page",
    },
    {
      link: "/seo",
      name: "Seo",
      imgUrl: "../img/seo.png",
      imgAlt: "Seo image",
      label: "Move to Seo services Page",
    },
  ];
  const home_arr_4 = [
    {
      link: "/ecommerce",
      name: "Ecommerce",
      imgUrl: "../img/Ecommerce_home_image.png",
      imgAlt: "Ecommerce image",
      label: "Move to Ecommerce Website Development services Page",
    },
    {
      link: "/branding",
      name: "Brand Promotion",
      imgUrl: "../img/brand.png",
      imgAlt: "Brand Promotion image",
      label: "Move to Branding services Page",
    },

    {
      link: "/graphic-design",
      name: "Graphic Design",
      imgUrl: "../img/graphicdesign.png",
      imgAlt: "Graphic Design image",
      label: "Move to Graphic Design services Page",
    },
  ];

  const home_arr_2 = [
    {
      link: "/digital-marketing",

      title: "Digital Marketing",
      pera: "We offer a wide range of Digital Marketing Services, Including Search Engine Optimization (SEO), Social Media Marketing, and Email Marketing. These services are designed to help clients reach their target audiences and elevate their business.",
      label: "Move to Digital Marketing services Page",
    },
    {
      link: "/web-development",

      title: "Web Development",
      pera: "Bring your digital aspirations to life with our web development services. Whether it's a corporate site or an e-commerce platform, we construct robust and scalable websites customized to meet your specific needs.",
      label: "Move to Web Development services Page",
    },
    {
      link: "/social-media-marketing",

      title: "Social Media Marketing",
      pera: " Build connections with your audience through effective social media strategies.We expertly manage and optimize your social media channels to boost brand visibility, foster relationships, and drive conversions.",
      label: "Move to Social Media Marketing services Page",
    },
    {
      link: "/seo",
      title: "SEO (Search Engine Optimization)",
      pera: "Enhance your online presence and rankings with our SEO services. We optimize your website to secure high positions in search engine results, resulting in increased organic traffic and higher conversion rates.",
      label: "Move to Seo services Page",
    },
    {
      link: "/branding",
      title: "Branding",
      pera: " Leave a lasting mark with our digital branding solutions. From crafting logos to shaping brand messages, we ensure your online presence consistently and memorably reflects your brand identity.",
      label: "Move to Branding services Page",
    },
    {
      link: "/ecommerce",
      title: "E-commerce",
      pera: "We build websites on leading e-commerce builder platforms such As Shopify, Wix, Woo-commerce by WordPress React-JS, Node-JS, and Trending Web Technologies. Our expertise allows us to tailor every aspect of your e-commerce website to fit your business seamlessly.",
      label: "Move to E-commerce website development services Page",
    },
  ];
  const home_arr_3 = [
    {
      img: "../img/Understanding_Your_Objectives.png",
      alt: "Understanding_Your_Objectives",
      heading: "Understanding Your Objectives",
      pera: "We pay attention! Our digital marketing team engages in interviews and questionnaires to grasp your business goals thoroughly.",
    },
    {
      img: "../img/Assessing_Your_Digital_Presence.png",
      alt: "Assessing_Your_Digital_Presence",
      heading: "Assessing Your Digital Presence",
      pera: "We analyze! Examining your presence on various digital platforms, intentional or automated, helps us gauge your brand's current digital standing.",
    },
    {
      img: "../img/Idendifying_Your_Audience.png",
      alt: "Idendifying_Your_Audience",
      heading: "Identifying Your Audience",
      pera: "We pinpoint! Combining research and industry insights, we define target audiences likely to connect with your brand, increasing the likelihood of success.",
    },
    {
      img: "../img/Crafting_a_Strategic_Plan.png",
      alt: "Crafting_a_Strategic_Plan",
      heading: "Crafting a Strategic Plan",
      pera: "We plan! Before diving into any digital marketing services, we thoroughly study your goals and audience, creating a comprehensive and phased digital strategy.",
    },
    {
      img: "../img/Digital_Execution_in_Action.png",
      alt: "Digital_Execution_in_Action",
      heading: "Digital Execution in Action",
      pera: "We implement! From developing websites to social media marketing, SEO, email marketing, or paid promotions, we bring the strategy to life across digital platforms.",
    },
    {
      img: "../img/Continuous_Optimization.png",
      alt: "Continuous_Optimization",
      heading: "Continuous Optimization",
      pera: "We assess! Evaluating the outcomes of all digital marketing campaigns, we fine-tune performance to align with goals, whether it's expanding reach, enhancing engagement, or boosting conversions.",
    },
  ];

  const home_faq_arr = [
    {
      H4: "What digital marketing services do you offer?",
      pera: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and all kind of social media platform. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. It plays an important role in broadening reach, increasing visibility, and driving growth by utilizing various online channels and platforms. Our agency  specializes in providing a range of services to empower businesses in the digital world.We provide a comprehensive range of digital marketing services which include search engine optimization (SEO), social media management, advertising, content marketing, web design and development, branding and email marketing. Our goal is to create a customized strategy that aligns with your business objectives and drives meaningful results. ",
    },
    {
      H4: "What you offer in your website development service?",
      pera: "Our web development services include everything from creating responsive and user-friendly websites to custom web applications. Whether you need a new website or want to change your existing one, we focus on delivering a seamless online experience for your users while aligning with your brand identity. From E-commerce to personal website  we provide all kind of website development. Our Website are build with the help trendy web development technologies which include Java-script, Node-JS, React-JS and WordPress.",
    },
    {
      H4: "What kind of social media marketing service do you provide?",
      pera: "Social Media Marketing (SMM) is a digital marketing strategy that involves using social media platforms to connect with the target audience, build brand awareness, and promote products or services. It encompasses various activities, including creating and sharing content on social media networks, engaging with followers, running paid advertising campaigns, and analysing performance metrics. Social media marketing is a powerful tool for building brand awareness, engagement with your target audience, and driving website traffic. We create strategic social media campaigns tailored to your business objectives, ensuring increased visibility, audience engagement, and ultimately, conversions. We offer a wide range of services which include profile setup, profile optimization, content creation, audience research, social media campaigns setup, analytics and reporting for all social media platforms like Facebook, Instagram, Twitter, LinkedIn and many more. ",
    },
    {
      H4: "What  is SEO and how it helps to grow my business?",
      pera: "SEO, or Search Engine Optimization, is a comprehensive set of strategies and practices aimed at enhancing a website's visibility on search engines like Google. Its importance for businesses lies in its ability to expand online presence, drive organic traffic, and significantly improve visibility in the digital landscape. By optimizing various elements, SEO ensures that your business is not just a part of the online conversation but a prominent player, attracting potential customers and fostering growth. Search Engine Optimization (SEO) is essential for improving your website's visibility on search engines. Our SEO services focus on optimizing your website's content, structure, and performance to rank higher in search results. This leads to increased organic traffic, higher conversion rates, and improved overall online visibility for your business.",
    },
    {
      H4: "What graphic design services do you offer?",
      pera: "Graphic Design is like the art of using pictures and colours to tell your audience that what is your brand is all about. what kind of service or product you offer. It's the creative way of making your brand stand out in various social media platform. Our graphic design services cover everything from logos and branding elements to marketing collateral. By creating visually appealing and impactful designs, we help elevate your brand, making it more memorable and recognizable to your target audience.",
    },
    {
      H4: "What types of branding services do you offer?",
      pera: "Branding is about making your business recognizable and creating a positive impression. It's like giving your business a special identity that people can easily connect with and remember. A strong brand helps your business stand out in a crowded market and build trust with your customers. So, it's not just a logo; it's about the entire strategy that we build to make your business a impactful brand. In Ahmedabad, like JK INFOSYS GLOBAL, a branding agency , provides various services such as brand strategy, logo design, visual identity development, messaging, marketing campaigns, and brand management.",
    },
    {
      H4: "How will you, measure the success of my campaign?",
      pera: "We use a data-driven approach, tracking key performance indicators (KPIs) aligned with your specific goals. This could include website traffic, lead generation, conversion rates, and social media engagement. As the best digital marketing agency in Ahmedabad, we provide regular reports to keep you informed and demonstrate the impact of your campaign.",
    },
    {
      H4: "Do I need to be on all social media platforms to work with the best digital marketing company in Ahmedabad?",
      pera: "Not necessarily. The best platforms for your business depend on your target audience and brand voice. As the best social media marketing agency in Ahmedabad, we'll help you identify the platforms where you can connect with your ideal customers and develop engaging social media strategies.",
    },
    {
      H4: "Can you design both print and digital media?",
      pera: "At JK Infosys Global, the best graphic design agency in Ahmedabad, we're experts in crafting impactful visuals for both print and digital media. We seamlessly translate your brand identity across platforms, ensuring consistency and memorability in everything from brochures to websites.",
    },
    {
      H4: "What SEO services do you offer?",
      pera: "As the best SEO agency in Ahmedabad, our SEO services go beyond the ordinary. We take a comprehensive approach to ensure a customized optimization strategy tailored to your specific business needs. From on-page optimizations and content creation to backlink building, keyword research, and technical SEO, our services are designed to address every aspect of enhancing your online presence and driving organic traffic. We leave no stone unturned in our quest to make your website a magnet for your target audience.",
    },
    {
      H4: "Can you redesign my existing website for a modern look?",
      pera: "Absolutely! As best website development agency in Ahmedabad, we’re really good at making your online stuff look modern and cool. We focus on updating how things appear, like giving your digital home a fresh touch. Whether it's making your website responsive, adding new info, or giving it a total makeover, we're here to make your online space look awesome for today's crowd. Our main goal is to make your online place not just look good but also feel right for the times we're in. We're like decorators for your digital world, making sure it stands out and feels super modern. Let us be the ones to give your online look a fresh and trendy vibe!",
    },
  ];

  const websiteName = {
    "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "JK Infosys Global: Best Digital Marketing Agency In Ahmedabad",
      "url" : "https://www.jkinfosysglobal.com/"
  }
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://jkinfosysglobal.com/#webpage",
    url: "https://jkinfosysglobal.com/",
    name: "JK Infosys Global: Best Digital Marketing Agency In Ahmedabad",
    datePublished: "2024-04-01T16:22:27+05:00",
    dateModified: "2024-04-01T17:50:25+05:30",
    inLanguage: "en-US",
    about: {
      "@type": "Organization",
      "@id": "https://jkinfosysglobal.com/#organization",
      name: "JK Infosys Global",
      url: "https://jkinfosysglobal.com/",
      sameAs: [
        "https://www.facebook.com/JKinfosysGlobal/",
        "https://twitter.com/JkinfosysG80663",
        "https://www.instagram.com/jkinfosysglobal/",
      ],
      email: "hello@jkinfosysglobal.com",
      address: {
        "@type": "PostalAddress",
        streetAddress: "1322, Shivalik Shilp-II, opp. Itc Narmada, Hotel, I I M, Vastrapur.",
        addressRegion: "Ahmedabad",
        postalCode: "380015",
        addressCountry: "India",
      },
      logo: {
        "@type": "ImageObject",
        "@id": "https://jkinfosysglobal.com/#logo",
        url: "https://jkinfosysglobal.com/img/logo.png",
        contentUrl: "https://jkinfosysglobal.com/img/logo.png",
        caption: "JK Infosys Global",
        inLanguage: "en-US",
        width: 160,
        height: 64,
      },
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "9081081005/06",
        contactType: "customer support",
      },
      location: {
        "@type": "Place",
        "@id": "https://jkinfosysglobal.com/#place",
        hasMap: "https://maps.app.goo.gl/8wBdpr9Z6YVJMNzp6",
        geo: {
          "@type": "GeoCoordinates",
          latitude: 23.029109,
          longitude: 72.529543,
        },
        address: {
          "@type": "PostalAddress",
          streetAddress: "1322, Shivalik Shilp-II, opp. Itc Narmada, Hotel, I I M, Vastrapur.",
          addressRegion: "Ahmedabad",
          postalCode: "380015",
          addressCountry: "India",
        },
      },
      potentialAction: {
        "@type": "SearchAction",
        target: "https://jkinfosysglobal.com/portfolio?s={search_term_string}",
        "query-input": {
          "@type": "PropertyValueSpecification",
          valueRequired: "http://schema.org/True",
          valueName: "search_term_string",
        },
      },
    },
  };
  const LocalBusiness = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "JK Infosys Global",
    address: {
      "@type": "PostalAddress",
      streetAddress: "1322, Shivalik Shilp-II, opp. Itc Narmada, Hotel, I I M, Vastrapur.",
      addressLocality: "Ahmedabad",
      addressRegion: "Gujarat",
      postalCode: "380015",
    },
    image: "https://www.jkinfosysglobal.com/img/rocket.png",
    email: "hello@jkinfosysglobal.com",
    telePhone: "+919081081006",
    url: "https://www.jkinfosysglobal.com/",
    openingHours: "Mo,Tu,We,Th,Fr,Sa 10:00-19:00",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        opens: "10:00",
        closes: "19:00",
      },
    ],
    geo: {
      "@type": "GeoCoordinates",
      latitude: "23.029109  ",
      longitude: "72.529543  ",
    },
  };
  const faqstructuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What digital marketing services do you offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and all kinds of social media platforms. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. It plays an important role in broadening reach, increasing visibility, and driving growth by utilizing various online channels and platforms. Our agency  specializes in providing a range of services to empower businesses in the digital world. We provide a comprehensive range of digital marketing services which include search engine optimization (SEO), social media management, advertising, content marketing, web design and development, branding, and email marketing. Our goal is to create a customized strategy that aligns with your business objectives and drives meaningful results.",
        },
      },
      {
        "@type": "Question",
        name: "What you offer in your website development service?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Our web development services include everything from creating responsive and user-friendly websites to custom web applications. Whether you need a new website or want to change your existing one, we focus on delivering a seamless online experience for your users while aligning with your brand identity. From E-commerce to personal websites, we provide all kinds of website development. Our Websites are built with the help of trendy web development technologies which include Java-script, Node-JS, React-JS, and WordPress.",
        },
      },
      {
        "@type": "Question",
        name: "What kind of social media marketing service do you provide?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Social Media Marketing (SMM) is a digital marketing strategy that involves using social media platforms to connect with the target audience, build brand awareness, and promote products or services. It encompasses various activities, including creating and sharing content on social media networks, engaging with followers, running paid advertising campaigns, and analyzing performance metrics. Social media marketing is a powerful tool for building brand awareness, engagement with your target audience, and driving website traffic. We create strategic social media campaigns tailored to your business objectives, ensuring increased visibility, audience engagement, and ultimately, conversions. We offer a wide range of services which include profile setup, profile optimization, content creation, audience research, social media campaign setup, analytics, and reporting for all social media platforms like Facebook, Instagram, Twitter, LinkedIn, and many more.",
        },
      },
      {
        "@type": "Question",
        name: "What is SEO and how it helps to grow my business?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "SEO, or Search Engine Optimization, is a comprehensive set of strategies and practices aimed at enhancing a website's visibility on search engines like Google. Its importance for businesses lies in its ability to expand its online presence, drive organic traffic, and significantly improve visibility in the digital landscape. By optimizing various elements, SEO ensures that your business is not just a part of the online conversation but a prominent player, attracting potential customers and fostering growth. Search Engine Optimization (SEO) is essential for improving your website's visibility on search engines. Our SEO services focus on optimizing your website's content, structure, and performance to rank higher in search results. This leads to increased organic traffic, higher conversion rates, and improved overall online visibility for your business",
        },
      },
      {
        "@type": "Question",
        name: "What graphic design services do you offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Graphic Design is like the art of using pictures and colors to tell your audience what your brand is all about. what kind of service or product you offer. It's a creative way of making your brand stand out on various social media platforms. Our graphic design services cover everything from logos and branding elements to marketing collateral. By creating visually appealing and impactful designs, we help elevate your brand, making it more memorable and recognizable to your target audience.",
        },
      },
      {
        "@type": "Question",
        name: "What types of branding services do you offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Branding is about making your business recognizable and creating a positive impression. It's like giving your business a special identity that people can easily connect with and remember. A strong brand helps your business stand out in a crowded market and build trust with your customers. So, it's not just a logo; it's about the entire strategy that we build to make your business an impactful brand. In Ahmedabad, JK INFOSYS GLOBAL, a branding agency , provides various services such as brand strategy, logo design, visual identity development, messaging, marketing campaigns, and brand management.",
        },
      },
    ],
  };

  return (
    <div>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
        <script type="application/ld+json">{JSON.stringify(websiteName)}</script>
        <script type="application/ld+json">{JSON.stringify(faqstructuredData)}</script>
        <script type="application/ld+json">{JSON.stringify(LocalBusiness)}</script>
        <title>#1 Best Digital Marketing Agency in Ahmedabad</title>
        <meta
          name="description"
          content="JK Infosysglobal, India's top-ranking agency . #1 Best digital marketing & web development agency , delivering global services for unparalleled growth."
          data-rh="true"
        />
        <meta property="og:title" content="JK Infosys Global: Best Digital Marketing Agency In Ahmedabad"></meta>
      </Helmet>
      <div className="pb-10">
        <div
          style={{ backgroundImage: `url(../img/home_header_image.png)` }}
          className="md:grid    grid-cols-6 "
        >
          <div className="col-span-2 flex flex-col ">
            <div className=" px-20 pt-32 ">
              <img width="100%" height="100%" alt="rocket" src="../img/rocket.png" />
            </div>
            <div className="uppercase  text-center px-5 my-auto ">
              <div className=" py-5">
                <p className=" px-3 py-2  inline-block bg-blue-950  text-yellow-400">
                  CONTENT + DESIGN + CODE
                </p>
              </div>
              <p className="bebas-neue-regular  text-blue-950 text-3xl lg3:text-6xl ">
                creative solutions effective results
              </p>
            </div>
          </div>
          <div className="  col-span-3  md:grid grid-cols-2 grid-rows-2">
            {home_arr_1.map((e, i) => (
              <div
                key={i} // Don't forget to add a unique key when mapping over an array
                className={`  ${i === 0 || i === 3 ? " bg-blue-950" : ""} `}
              >
                <Services_sections
                  imgUrl={e.imgUrl}
                  imgAlt={e.imgAlt}
                  title={e.name}
                  linkToServices={e.link}
                  label={e.label}
                />
                <div className="flex justify-center py-2 bg-white items-center md:hidden">
                  <button
                    className={`  ${
                      i % 2 === 0 ? " bg-yellow-400 text-blue-950  " : "bg-blue-950 text-yellow-400"
                    } py-1.5 px-4`}
                    aria-label="All services"
                  >
                    <span>{e.name}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="  col-span-1 ">
            {home_arr_4.map((e, i) => (
              <div
                key={i} // Don't forget to add a unique key when mapping over an array
                className={`  ${i === 1 ? " bg-blue-950" : ""} `}
              >
                <Services_sections
                  imgUrl={e.imgUrl}
                  imgAlt={e.imgAlt}
                  title={e.name}
                  linkToServices={e.link}
                  label={e.label}
                />
                <div className="flex justify-center py-2 bg-white items-center md:hidden">
                  <button
                    className={`  ${
                      i % 2 === 0 ? " bg-yellow-400 text-blue-950  " : "bg-blue-950 text-yellow-400"
                    } py-1.5 px-4`}
                    aria-label="All services"
                  >
                    <span>{e.name}</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <Popups />

        <div className="md:pt-20 pt-10 ">
          <div className="lg2:px-32 text-justify lg3:px-72 px-5  text-black">
            <h1 className="h1Classes ">Best Digital marketing agency in Ahmedabad</h1>
            <p className=" text-xl py-5 md:py-20">
              Introducing JK INFOSYS GLOBAL, your brand's digital partner, dedicated to elevating
              businesses on digital platforms. As the most adored digital marketing agency in
              Ahmedabad, our specialized team excels in Digital Strategy, Digital Branding, Social
              Media Marketing, Web Design, Web Development, Ecommerce Website Development and SEO.
              We craft memorable and results-driven digital marketing campaigns, and if you have
              challenges, we have innovative solutions. Whether you're selling a product or sharing
              a story, we possess the strategy and expertise to excel. If you're seeking a single
              digital marketing agency to navigate your brand's digital journey from awareness to
              engagement to revenue. Connect with us now!
            </p>
            <div className="py-10">
              <Link to="/contact-us" onClick={scrollHook}>
                <button className="btn" aria-label="TALK TO US">
                  <span>TALK TO US!</span>
                </button>
              </Link>
            </div>
            <Homesection arr={home_arr_2} title={"OUR SERVICES"} />
            <div className="py-10">
              <Link to="/contact-us" onClick={scrollHook}>
                <button className="btn" aria-label="TALK TO US">
                  <span>TALK TO US!</span>
                </button>
              </Link>
            </div>
          </div>
          <div className=" lg:grid     grid-cols-2">
            <div
              // style={{
              //   backgroundImage: `url(../img/Our_digital_process_image.png)`,
              // }}
              className="px-5 bg-blue-950 text-white   "
            >
              <div>
                <h2 className=" py-5 md:py-10  bebas-neue-regular   text-4xl text-center ">
                  Our Digital Process{" "}
                </h2>
                <p className=" text-xl md:px-20 ">
                  {" "}
                  At JK INFOSYS GLOBAL, we start by listening. As your brand's digital therapist, we
                  use insights, research, and experience to create effective strategies for
                  awareness, engagement, or revenue. The result? We're Ahmedabad's favorite digital
                  marketing agency!
                </p>
              </div>
              <div className="md:px-20">
                <img
                  width="100%"
                  height="100%"
                  alt="Our_digital_process_image"
                  src="../img/Our_digital_process_image.png "
                />
              </div>
            </div>
            <DigitalProcess arr={home_arr_3} />
          </div>
          <div className="pt-20 lg2:px-32 lg3:px-72 px-5 lg:grid md:px-20   grid-cols-2">
            <div className="   ">
              <div>
                <p className="text-blue-950 bebas-neue-regular text-xl">Our Approaches</p>
                <h2 className=" py-5 md:py-10 text-blue-950 text-4xl  bebas-neue-regular   ">
                  Take Your Brand to the Next Level
                </h2>
                <div className="grid md:grid-cols-2 py-10 gap-10">
                  <div className=" shadow-2xl shadow-pink-100 bg-pink-100 py-5 pl-5 pr-20 border-2 ">
                    <h2 className=" flex gap-2 text-blue-950 bebas-neue-regular">
                      <span className="     ">&#10004;</span> Communication
                    </h2>
                    <p>Strategies that strengthen your communication plan. </p>
                  </div>
                  <div className=" shadow-2xl shadow-yellow-100 bg-yellow-100 py-5 pl-5 pr-20  border-2">
                    <h2 className=" flex gap-2 text-blue-950 bebas-neue-regular">
                      <span className="     ">&#10004;</span> Connection
                    </h2>
                    <p>Build strong bonds with your audience. </p>
                  </div>
                </div>
                <div className="grid md:grid-cols-2 gap-10">
                  <div className=" shadow-2xl shadow-green-100 bg-green-100 py-5 pl-5 pr-20  border-4">
                    <h2 className=" flex gap-2 text-blue-950 bebas-neue-regular">
                      <span className="     ">&#10004;</span> Creativity
                    </h2>
                    <p>Add creative touches to make your brand stand out.</p>
                  </div>
                  <div className=" shadow-2xl shadow-blue-100 bg-blue-100 py-5 pl-5 pr-20  border-4">
                    <h2 className=" flex gap-2 text-blue-950 bebas-neue-regular">
                      <span className="     ">&#10004;</span> Consistency
                    </h2>
                    <p>Stick to your plan for guaranteed success. </p>
                  </div>
                </div>
                {/* <p className="text-justify text-xl ">
                  {" "}
                  We understand the web and your business. As a full-service
                  digital agency, we use our digital skills to boost your online
                  presence, attract more customers, and find new growth
                  opportunities. The results speak for themselves.
                </p> */}
                {/* {isSectionVisible && (
                  <div ref={sectionCount}>Your Section Content {count}</div>
                )} */}
              </div>
            </div>
            <div className=" ">
              <img
                width="100%"
                height="100%"
                alt="about_us_main_photo"
                src="../img/about_us_main_photo.png"
              />
            </div>
          </div>
        </div>

        <div className="lg2:px-32 lg3:px-72 px-5 ">
          <Faq_component arr={home_faq_arr} pera={"FREQUENTLY ASKED QUESTIONS"} />
        </div>

        <div className="md:px-40">
          <div
            style={{ backgroundImage: `url("../img/yellow_box.png")` }}
            className=" lg:flex bg-cover lg2:mx-40 px-10   items-center justify-center  "
          >
            <p className="py-10 text-3xl bebas-neue-regular text-blue-950 text-center ">
              Ready to Grow Your Business? Book a Free Consultation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
