// MyForm.js
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
// import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";

const PopContact = ({ closeModal }) => {
  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [isSubmitted1, setIsSubmitted1] = useState(false);

  // const handleCaptchaChange = () => {
  //   setIsCaptchaVerified(true);
  // };

  const sendEmail1 = (e) => {
    e.preventDefault();
    // if (isCaptchaVerified) {
    if (!isSubmitted1) {
      closeModal();

      emailjs
        .sendForm(
          "service_y0iywns",
          "template_r7zwwyl",
          e.target,
          "nkb8tMPaICeBVpMW8"
        )
        .then((result) => {
          if (!alert("Data Stored"));
          //document.location = "/"
          alert("Email sent successfully:", result.text);
        })
        .catch((error) => {
          alert("plz fill the data");
          console.error("Email error:", error);
        });
    }
    // }
    setIsSubmitted1(true);
  };

  return (
    <div className=" ustify-center items-center ">
      <div
        // style={{ backgroundImage: `url(../img/form_image.png)` }}
        // w-full   items-center justify-center      transition-transform transform  md:hover:shadow-md
        className="   p-5 md:p-0   "
      >
        <div className=" p-5 md:p-10 border-yellow-400  border-8    bg-blue-950 text-white w-full shadow-md  ">
          <div className=" flex justify-end    ">
            <div
              className=" bg-black text-white rounded-full  "
              onClick={closeModal}
            >
              <AiOutlineClose size={28} />
            </div>
          </div>

          <form className=" text-black" onSubmit={sendEmail1}>
            <div className="md:flex  justify-between gap-8">
              <div className=" mb-4">
                <label
                  htmlFor="name"
                  className="block text-white  text-sm font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="user_name1"
                  className={"border  p-2 w-full "}
                  placeholder="Your name"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block text-white  text-sm font-bold mb-2"
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="user_phone_number1"
                  className={"border  p-2 w-full "}
                  placeholder="Your phone number"
                  required
                />
              </div>
            </div>
            <div className="md:flex  justify-between gap-8  ">
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-white  text-sm font-bold mb-2"
                >
                  Email
                </label>
                <input
                  required
                  type="email"
                  name="user_email1"
                  className={"border  p-2 w-full "}
                  placeholder="Your email"
                />
              </div>

              <div className="mb-4">
                <label className="block  text-white text-sm font-bold mb-2">
                  Service Description
                </label>

                <input
                  name="user_service_description1"
                  required
                  className="border  p-2 w-full"
                  placeholder="Service Description"
                />
              </div>
            </div>

            {/* <div className="mb-4">
              <label
                htmlFor="description"
                className="block text-white text-sm font-bold mb-2"
              >
                Description
              </label>
              <textarea
                id="description"
                name="message1"
                className="border border-white p-2 w-full"
                placeholder="Your description"
                rows="4"
              />
            </div> */}
            <div className=" flex flex-col md:space-x-16 items-center justify-center">
              {/* <div className=" mb-4  ">
                <ReCAPTCHA
                  sitekey="6Le6uVAoAAAAADGgAJ6aWn-JaELDnisVFXA7Le4r"
                  onChange={handleCaptchaChange}
                />
              </div> */}

              <div className=" my-2 ">
                <button
                  type="submit"
                  className="bg-gray-400  py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray"
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopContact;
