import React from "react";

const Social = () => {
  return (
    <div className=" w-28 py-3  gap-10  grid-cols-4 items-center  grid">
      <div className="    ">
        <a href="https://www.facebook.com/JKinfosysGlobal/" target="_blank" aria-label="facebook Link">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            className=" inline-block hover:shadow-2xl rounded-full p-2 text-xs font-medium uppercase leading-normal text-white shadow-md duration-500 transition   ease-in-out  shadow-black  focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            // style="background-color: #1877f2">
            style={{ backgroundColor: "#1877f2" }}
            aria-label="Contact on facebook"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
            </svg>
          </button>
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/company/jk-infosys-global/" target="_blank" aria-label="linkedin Link">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            className=" inline-block rounded-full p-2 text-xs font-medium uppercase leading-normal text-white  transition shadow-md duration-500 shadow-black ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            // style="background-color: #0077b5">
            style={{ backgroundColor: "#0077b5" }}
            aria-label="Contact on linkedin"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
            </svg>
          </button>
        </a>
      </div>

      <div>
        <a href="https://twitter.com/JkinfosysG80663" target="_blank" aria-label="Twitter Link">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            className=" inline-block rounded-full p-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-500 shadow-black  ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            // style="background-color: #1da1f2">
            style={{ backgroundColor: "#000000" }}
            aria-label="Contact on Twitter"
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
            </svg> */}

            <svg
              width="19"
              viewBox="0 0 810 809.999993"
              height="19"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
            >
              <defs>
                <clipPath id="c9f28d5a19">
                  <path
                    d="M 107.113281 136.902344 L 702.613281 136.902344 L 702.613281 673.152344 L 107.113281 673.152344 Z M 107.113281 136.902344 "
                    clip-rule="nonzero"
                  />
                </clipPath>
              </defs>

              <rect
                x="-81"
                width="972"
                fill="none"
                y="-80.999999"
                height="971.999992"
                fill-opacity="1"
              />
              <g clip-path="url(#c9f28d5a19)">
                <path
                  fill="#ffffff"
                  d="M 481.191406 357.949219 L 636.074219 136.902344 L 559.164062 136.902344 L 442.660156 303.125 L 338.5625 155.800781 L 325.066406 137.425781 L 325.066406 136.902344 L 107.113281 136.902344 L 156.632812 206.464844 L 333.722656 458.433594 L 183.195312 673.152344 L 260.105469 673.152344 L 372.230469 513.191406 L 471.03125 653.839844 L 484.386719 673.152344 L 702.613281 673.152344 L 653.09375 602.222656 Z M 616.503906 629.058594 L 507.617188 629.058594 L 193.222656 185.761719 L 304.179688 185.761719 L 303.628906 184.050781 L 616.640625 629.058594 Z M 616.503906 629.058594 "
                  fill-opacity="1"
                  fill-rule="nonzero"
                />
              </g>
            </svg>

            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="currentColor" // Set the fill color to match the desired color
              viewBox="0 0 24 24"
            >
              <path d="M23.813 4.313c-.837.37-1.738.62-2.688.735.964-.576 1.7-1.493 2.048-2.577-.896.53-1.889.912-2.945 1.123-.844-.897-2.043-1.458-3.375-1.458-3.045 0-5.516 2.471-5.516 5.516 0 .433.048.855.142 1.262-4.587-.23-8.658-2.482-11.364-5.9-.476.81-.748 1.752-.748 2.754 0 1.906.971 3.588 2.448 4.583-.904-.03-1.758-.278-2.497-.693v.07c0 2.657 1.893 4.87 4.398 5.366-.46.126-.943.192-1.44.192-.352 0-.695-.034-1.033-.093.696 2.18 2.715 3.758 5.115 3.802-1.872 1.467-4.001 2.346-6.322 2.346-.412 0-.81-.025-1.209-.072 2.252 1.45 4.915 2.295 7.77 2.295 9.313 0 14.408-7.721 14.408-14.408 0-.22-.005-.44-.014-.657.991-.716 1.854-1.617 2.527-2.637z" />
            </svg> */}
          </button>
        </a>
      </div>

      <div>
        <a href="https://www.instagram.com/jkinfosysglobal/" target="_blank" aria-label="instagram Link">
          <button
            type="button"
            data-te-ripple-init
            data-te-ripple-color="light"
            className=" inline-block rounded-full  p-2 text-xs font-medium uppercase leading-normal text-white shadow-md transition duration-500 shadow-black  ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            style={{ backgroundColor: "#c13584" }}
            aria-label="Check our instagram"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
            </svg>
          </button>
        </a>
      </div>
    </div>
  );
};

export default Social;
