import React, { useState } from "react";
import PopContact from "./PopContact";

const Popup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="">
            <PopContact closeModal={closePopup} />
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
// // useEffect(() => {
// //   // Disable scrolling when the pop-up is open
// //   // if (isOpen) {
// //   //   document.body.style.overflow = "hidden";
// //   // } else {
// //   //   document.body.style.overflow = "auto";
// //   // }

// //   return () => {
// //     // Cleanup: Enable scrolling when the pop-up is closed
// //     document.body.style.overflow = "auto";
// //     document.body.style.overflow = "auto"
// //   };
// }, [isOpen]);
