import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { hydrate, render } from "react-dom";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// ReactDOM.render(<App />, document.getElementById("root"));
const root = document.getElementById("root");

if (root.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>,
    root
  );
} else {
  render(
    <React.StrictMode>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </React.StrictMode>,
    root
  );
}
// root.render(
// <React.StrictMode>
//   <HelmetProvider>
//     <App />
//   </HelmetProvider>
// </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
