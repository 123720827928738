// MyForm.js
import React, { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import scrollHook from "../Hooks/scrollHook";
import emailjs from "emailjs-com";
import "../animations.css";

const ContactForm = () => {
  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    // if (isCaptchaVerified) {
    if (!isSubmitted) {
      scrollHook();
      emailjs
        .sendForm("service_y0iywns", "template_nrgjnpr", e.target, "nkb8tMPaICeBVpMW8")
        .then((result) => {
          if (!alert("Data Stored"));

          alert("Email sent successfully:", result.text);
        })
        .catch((error) => {
          alert("plz fill the data");
          console.error("Email error:", error);
        });
    }
    // }
    setIsSubmitted(true);
  };

  const options = [
    "Select an Option",
    "Digital Marketing",
    "Website Development",
    "SEO",
    "Social Media Marketing",
    "Graphic Designing ",
    "Brand Promotion",
    "Ecommerce-Website",
  ];

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSelectChange = (e) => {
  //   setFormData({ ...formData, selectedOption: e.target.value });
  // };

  // const handleCaptchaChange = () => {
  //   setIsCaptchaVerified(true);
  // };

  return (
    <div className=" ustify-center items-center ">
      <div
        // style={{ backgroundImage: `url(../img/form_image.png)` }}
        // w-full   items-center justify-center      transition-transform transform  md:hover:shadow-md
        className="   lg:grid p-5 md:p-0   grid-cols-6  "
      >
        <div className="    col-span-3 p-5 md:p-10 border-yellow-400  border-8    bg-blue-950  w-full shadow-md  ">
          <form onSubmit={sendEmail}>
            <div className="md:grid grid-cols-2md:space-x-16">
              <div className=" mb-4">
                <label
                  htmlFor="name"
                  text-white
                  className="block text-white text-sm font-bold mb-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="user_name"
                  className={"border  p-2 w-full "}
                  placeholder="Your name"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="email" className="block text-white text-sm font-bold mb-2">
                  Email
                </label>
                <input
                  required
                  type="email"
                  id="email"
                  name="user_email"
                  className={"border  p-2 w-full "}
                  placeholder="Your email"
                />
              </div>
            </div>
            <div className="md:grid grid-cols-2 md:space-x-16">
              <div className="mb-4">
                <label htmlFor="phoneNumber" className="block text-white text-sm font-bold mb-2">
                  Phone Number
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  name="user_phone_number"
                  className={"border  p-2 w-full "}
                  placeholder="Your phone number"
                  required
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="selectedOption"
                  className="block text-white text-sm font-bold mb-2  "
                >
                  Select an Option
                </label>
                <select
                  id="selectedOption"
                  name="user_service"
                  className="border border-white p-2 w-full text-black"
                  required
                >
                  <option value="" disabled>
                    {/* Select an option */}
                  </option>
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="description" className="block text-white text-sm font-bold mb-2">
                Description
              </label>
              <textarea
                id="description"
                name="message"
                className="border border-white p-2 w-full"
                placeholder="Your description"
                rows="4"
              />
            </div>
            <div className=" flex flex-col md:space-x-16 items-center justify-center">
              {/* <div className=" mb-4  ">
                <ReCAPTCHA
                  sitekey="6Le6uVAoAAAAADGgAJ6aWn-JaELDnisVFXA7Le4r"
                  onChange={handleCaptchaChange}
                />
              </div> */}

              <div className=" my-2 ">
                <button
                  type="submit"
                  className="bg-gray-400  py-2 px-4 rounded-md hover:bg-gray-300 focus:outline-none focus:shadow-outline-gray"
                  aria-label="Submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className=" col-span-3 bg-yellow-400 ">
          <img
            src="../img/contact_us_image.png"
            alt="My Image"
            width={"100%"}
            height={"100%"}
            className="w-full  "
          />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
