import React from 'react'

const Header_for_faq_privacy = ({pera1,pera2}) => {
  return (
    <div
          style={{backgroundImage:`url("../img/home_header_image.png")`}}
    >      
    <div className="  text-center  py-16 ">
        <p className="py-5  text-3xl lg:text-5xl bebas-neue-regular text-blue-950">
          {pera1}
        </p>
        <p className="py-5 text-center  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          {pera2}
        </p>
      </div>
    </div>
  )
}

export default Header_for_faq_privacy;
