import "./App.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import React, { useState } from "react";
import "animate.css";
import WOW from "wow.js";
import { FloatingWhatsApp } from "react-floating-whatsapp";
// import LandingPage from "./screens/LandingPage";
import Home from "./screens/Home";
import About from "./screens/About";
import ContactUs from "./screens/ContactUs";
import Branding from "./screens/Branding";
import GraphicDesign from "./screens/GraphicDesign";
import Portfolio from "./screens/Portfolio";
import Seo from "./screens/Seo";
import Services from "./screens/Services";
import DigitalMarketing from "./screens/DigitalMarketing";
import SocialMediaMarketing from "./screens/SocialMediaMarketing";
import { TbDeviceMobileSearch } from "react-icons/tb";
import WebDevelopment from "./screens/WebDevelopment";
import PrivacyPolicy from "./screens/PrivacyPolicy";

import { AiFillHome } from "react-icons/ai";
import { IoCodeWorkingSharp } from "react-icons/io5";
import { GrContact } from "react-icons/gr";
import { RiSeoFill } from "react-icons/ri";

import { VscWorkspaceTrusted } from "react-icons/vsc";
import Nav from "./Component/Nav";

import { TbWorldCode, TbSocial } from "react-icons/tb";
import { SiTaichigraphics, SiGooglemybusiness } from "react-icons/si";
import Ecommerce_website from "./screens/Ecommerce";

import Footer from "./Component/Footer";
import { BsGraphUpArrow } from "react-icons/bs";
import Faq from "./screens/Faq";

function App() {
  const wow = new WOW();
  wow.init();

  const arr_NAV = [
    { link: "/", name: "Home", icon: <AiFillHome size={30} /> },
    { link: "/about", name: "About", icon: <IoCodeWorkingSharp size={30} /> },
  ];
  const arr_NAV1 = [
    {
      link: "/digital-marketing",
      name: "Digital Marketing",
      icon: <TbSocial size={30} />,
    },
    {
      link: "/web-development",
      name: "Website Development",
      icon: <TbWorldCode size={30} />,
    },
    {
      link: "/social-media-marketing",
      name: "Social Media Marketing",
      icon: <TbDeviceMobileSearch size={30} />,
    },
    { link: "/seo", name: "Seo", icon: <RiSeoFill size={30} /> },
    {
      link: "/ecommerce",
      name: "Ecommerce-Website",
      icon: <BsGraphUpArrow size={30} />,
    },
    {
      link: "/branding",
      name: "Branding",
      icon: <SiGooglemybusiness size={30} />,
    },

    {
      link: "/graphic-design",
      name: "Graphic Design",
      icon: <SiTaichigraphics size={30} />,
    },
  ];
  const arr_NAV2 = [
    {
      link: "/portfolio",
      name: "Portfolio",
      icon: <VscWorkspaceTrusted size={30} />,
    },
    { link: "/contact-us", name: "Contact Us", icon: <GrContact size={30} /> },
  ];
  const arr_NAV3 = [
    {
      link: "/privacy_policy",
      name: "Privacy Policy",
    },
    { link: "/faq", name: "FAQ" },
  ];

  return (
    <div className="   text-black ">
      <Router>
        <div className="   bg-local  ">
          <div className="  ">
            <FloatingWhatsApp
              phoneNumber="+919081081006"
              accountName="JK Infosys Global"
              statusMessage="Typically replies within 1 hour"
              allowEsc
              allowClickAway
              avatar={"../img/WhatsAppImage.jpg"}
              notification
              notificationSound
            />
            <Nav
              arr_NAV={arr_NAV}
              arr_NAV1={arr_NAV1}
              arr_NAV3={arr_NAV3}
              arr_NAV2={arr_NAV2}
            />
          </div>
        </div>
        <hr />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/branding" element={<Branding />} />
          <Route path="/graphic-design" element={<GraphicDesign />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/social-media-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route path="/digital-marketing" element={<DigitalMarketing />} />
          <Route path="/web-development" element={<WebDevelopment />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/ecommerce" element={<Ecommerce_website />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/seo" element={<Seo />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>

        <Footer arr_NAV3={arr_NAV3} arr_NAV1={arr_NAV1} />
      </Router>
    </div>
  );
}

export default App;
