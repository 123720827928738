import { useState, useEffect, useRef } from "react";

const useCounter = () => {
  const [count, setCount] = useState(0);
  const sectionCount = useRef(null);
  const [isSectionVisible, setIsSectionVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!sectionCount.current) return;

      const section = sectionCount.current;
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      const sectionBottom = sectionTop + sectionHeight;
      const isSectionInViewport =
        window.scrollY + window.innerHeight > sectionTop &&
        window.scrollY < sectionBottom;

      if (isSectionInViewport && !isSectionVisible) {
        // Section has come into view, start the count
        setIsSectionVisible(true);
        const interval = setInterval(() => {
          setCount((prevCount) =>
            prevCount < 7 && prevCount >= -1 ? prevCount + 1 : prevCount
          );
        }, 100); // Update count every secondhttps://www.bing.com/api/v1/mediation/tracking?adUnit=379960&auId=c529f244-29d4-4319-beee-c2dccc78e13b&bidId=2&bidderId=4&cmExpId=LV1&oAdUnit=379960&publisherId=17160724&rId=1efd6c62-9169-49e9-ad71-4a9c65c8bda8&rlink=https%3A%2F%2Fwww.bing.com%2Faclick%3Fld%3De8daklZm06XQl8tWJBC6P4xDVUCUwakpORNdvJcLvB8Vab9yMRVungPfPHostD2eEBeTywqRV5lF_T2eeUiMXyPOuoK4YzIaLwwABpfkQ4m8jfWFXMyne02b40P164Y9M4f-qzoKU-Zs4ZSD1C1kKyRYbuOaj739JvuMcUp79A1BO1zsucZcWBlEZn_EQS0SLz_ZphQA%26u%3DaHR0cHMlM2ElMmYlMmZ2eWFwYXJhcHAuaW4lM2ZjYW1wYWlnbl9pZCUzZEJJTkdTQiUyNmFkZ3JvdXBpZCUzZDEzMzM3MDg4MTMwNDQxMzElMjZjcmVhdGl2ZSUzZCUyNmtleXdvcmQlM2R2eWFwYXIlMjUyMGludmVudG9yeSUyNTIwbWFuYWdlbWVudCUyNnRhcmdldGlkJTNka3dkLTgzMzU3ODEzNjY3NDEyJTNhbG9jLTkwJTI2cGxhY2VtZW50JTNkJTI2YWRwb3NpdGlvbiUzZCUyNmElM2RhJTI2ZGV2aWNlJTNkYyUyNmRldmljZW1vZGVsJTNkJTI2bWF0Y2h0eXBlJTNkZSUyNmxvY19waHlzaWNhbF9tcyUzZDE1Njg0NiUyNm1zY2xraWQlM2QwNmE4ODA1YWZjMWExNWUxODc2OWU2NDRiMDM0YjM5YiUyNnV0bV9zb3VyY2UlM2RiaW5nJTI2dXRtX21lZGl1bSUzZGNwYyUyNnV0bV9jYW1wYWlnbiUzZDYuVnlhcGFyLUJyYW5kZWQtY2FtcGFpZ24oQklOR1NCKSUyNnV0bV90ZXJtJTNkdnlhcGFyJTI1MjBpbnZlbnRvcnklMjUyMG1hbmFnZW1lbnQlMjZ1dG1fY29udGVudCUzZEJyYW5kZWQlMjUyMEtleXdvcmRzJTI1MjBvbmx5%26rlid%3D06a8805afc1a15e18769e644b034b39b&rtype=targetURL&tagId=edgechrntp-infopane-2&trafficGroup=zfa_ncnp&trafficSubGroup=ego

        // Clean up the interval when the component unmounts or the section is out of view
        return () => clearInterval(interval);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isSectionVisible, sectionCount]);

  return { count, sectionCount, isSectionVisible };
};

export default useCounter;
