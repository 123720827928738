import React from "react";
import { Link } from "react-router-dom";
import scrollHook from "../Hooks/scrollHook";

const DigitalProcess = ({ arr }) => {
  return (
    <div className="md:px-20 px-5 ">
      {arr.map((e) => (
        <div className="grid py-5  grid-cols-8">
          <img
            className=" col-span-2 md:col-span-1  w-full"
            width="100%"
            height="100%"
            alt={e.alt}
            src={e.img}
          />
          <div className=" col-span-6 md:col-span-7 pl-3 md:px-5">
            <h3 className=" text-xl text-blue-950  bebas-neue-regular  ">{e.heading}</h3>
            <p className=" text-sm">{e.pera}</p>
          </div>
        </div>
      ))}
      <div className="py-10 px-5">
        <Link to="/contact-us" onClick={scrollHook}>
          <button className="btn" aria-label="TALK TO US!">
            <span>TALK TO US!</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default DigitalProcess;
