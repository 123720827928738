import React, { useState } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { IoCallOutline } from "react-icons/io5";
import { BsFillBuildingFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
// import { useActionData } from "react-router-dom";
// import { v4 } from "uuid";

const ContactUs = () => {
  // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);

  // const handleCaptchaChange = () => {
  //   setIsCaptchaVerified(true);
  // };

  const sendEmail = (e) => {
    e.preventDefault();
    // if (isCaptchaVerified) {
    if (!isSubmitted) {
      emailjs
        .sendForm(
          "service_y0iywns",
          "template_r7zwwyl",
          e.target,
          "nkb8tMPaICeBVpMW8"
        )
        .then((result) => {
          if (!alert("Data Stored")) document.location = "/";

          alert("Email sent successfully:", result.text);
        })
        .catch((error) => {
          alert("plz fill the data");
          console.error("Email error:", error);
        });
      // }
    }
    setIsSubmitted(true);
  };

  return (
    <div className="my-20 lg2:px-32 lg3:px-72">
      <div class="container my-24 mx-auto md:px-6">
        <section class="mb-32">
          <div class="relative h-[100px] overflow-hidden bg-cover bg-[50%] bg-no-repeat "></div>
          <div class="container px-6 md:px-12">
            <div class="block rounded-lg bg-[hsla(0,0%,100%,0.8)] px-6 py-12 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]  dark:shadow-black/20 md:py-16 md:px-12 -mt-[100px] backdrop-blur-[30px]">
              <div class="flex flex-wrap">
                <div class="mb-12 w-full shrink-0 grow-0 basis-auto md:px-3 ">
                  {/* onSubmit={sendEmail}  */}
                  <p className="text-4xl uppercase text-blue-900   font-bold">
                    get in Touch
                  </p>
                  <h1 className="text-xl text-blue-1000 font-bold">
                    Your journey,Our efforts.Lets make it happen together
                  </h1>
                  <form
                    enctype="multipart/form-data"
                    method="post"
                    onSubmit={sendEmail}
                  >
                    <div className="mt-8">
                      <label className="">Full Name</label>
                      <br />
                      <input
                        type="text"
                        required
                        name="user_name"
                        className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                        placeholder="Enter your full name"
                      />
                      <br />
                    </div>
                    <div className="mt-8">
                      <label className="">Phone Number</label>
                      <br />
                      <input
                        type="number"
                        required
                        name="user_phone_number"
                        className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                        placeholder="Enter your Phone Number"
                      />
                      <br />
                    </div>

                    <div className="mt-8">
                      <label className="">Email</label>
                      <br />
                      <input
                        type="email"
                        required
                        name="user_email"
                        className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                        placeholder="Enter your full Email"
                      />
                      <br />
                    </div>

                    <div className="mt-8">
                      <label>Service Description</label>
                      <br />
                      <input
                        name="user_service_description"
                        required
                        className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                        placeholder="Service Description"
                      />
                      <br />
                    </div>

                    <div className="mt-8">
                      <label>Comment or Message</label>
                      <br />
                      <textarea
                        row="10"
                        s
                        required
                        name="message"
                        placeholder="Message"
                        className="border   rounded-lg  p-2 mt-2 mb-2 w-full"
                      ></textarea>
                    </div>

                    <div className=" my-14 ">
                      {/* <ReCAPTCHA
                        sitekey="6Le6uVAoAAAAADGgAJ6aWn-JaELDnisVFXA7Le4r"
                        onChange={handleCaptchaChange}
                      /> */}

                      <a href="" target="_blank" aria-label="SEND">
                        <button className=" bg-slate-900 py-2 my-5 text-white px-5  text-sm rounded-md " aria-label="Contact Us">
                          SEND
                        </button>
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div class="grid md:grid-cols-3 py-10 ">
        <div class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ease-in-out duration-300 hover:scale-110 hover:bg-zinc-300">
          <div className=" py-2 flex">
            {" "}
            {/* <img class="w-10 " src="../img/line.png" alt="" /> */}
            <p class=" px-2 py-1 text-blue-900 font-bold text-xl mx-auto">
              Registered Office
            </p>
          </div>
          <div className=" pt-8 px-8 lg3:px-14  flex">
            <BsFillBuildingFill size={32} className="font-bold lg:mx-2" />

            <p class="py-2 font-bold px-2">JK Infosys Global</p>
          </div>
          <p class="leading-8 text-center">
            1322, Shivalik Shilp-II, Opp. ITC Narmada Hotel, Vastrapur,
            Ahmedabad, Gujarat 380015
          </p>
        </div>
        <div class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ease-in-out duration-300 hover:scale-110 hover:bg-zinc-300">
          <a href="tel:+919081081005">
            <div className=" py-2 flex">
              {" "}
              {/* <img class="w-10 " src="../img/line.png" alt="" /> */}
              <p class=" px-2 py-1 text-blue-900 font-bold text-xl mx-auto">
                Make a Call
              </p>
            </div>
            <div className=" pt-8 px-8 lg3:px-10 flex">
              <IoCallOutline size={32} className=" font-bold  mt-1   " />
              <p class="py-2 font-bold px-2">+91 9081081005 / 06</p>
            </div>
            <p class="leading-8 text-center">Mon - Sat: 09am - 08pm</p>
          </a>
        </div>
        <div
          onClick={() => (window.location = "mailto:jkinfosysgloble@gmail.com")}
          class="col-span-1 px-5 my-10 lg:my-0 mx-6 border-2 shadow-2xl ease-in-out duration-300 hover:scale-110 hover:bg-zinc-300"
        >
          <div className=" py-2 flex">
            {" "}
            {/* <img class="w-10  " src="../img/line.png" alt="" /> */}
            <p class=" px-2 py-1 text-blue-900 font-bold text-xl mx-auto">
              Send a Mail
            </p>
          </div>
          <div className=" pt-8 px-8 lg3:px-14 flex">
            <AiOutlineMail size={32} className="font-bold mt-1  lg:mx-2" />
            <p class="py-2 font-bold px-2">JK Infosys Global</p>
          </div>
          <p class="leading-8 text-center">hello@jkinfosysglobal.com</p>
        </div>
      </div>
      <div className="my-10 shadow-2xl">
        <iframe
          className="w-full h-72"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.8572279160526!2d72.52693367604357!3d23.029013816086522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84c8d4d4139d%3A0x177fd3db1f47ada6!2sShivalik%20Shilp%202!5e0!3m2!1sen!2sin!4v1694080264059!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
