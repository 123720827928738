import React, { useState } from "react";
import ".././animations.css";
const Faq_component = ({ arr, pera }) => {
  const arr1 = arr;
  const [visibleItems, setVisibleItems] = useState([]);

  const toggleVisibility = (index) => {
    const updatedVisibility = [...visibleItems];
    updatedVisibility[index] = !updatedVisibility[index];
    setVisibleItems(updatedVisibility);
  };

  return (
    <div className="py-10">
      <h2 className="text-3xl md:4xl py-8  text-blue-950 bebas-neue-regular">
        {" "}
        {pera}
      </h2>

      <div className=" justify-center  ">
        {arr1.map((e, i) => {
          return (
            <div key={i} className="wow animate__animated animate__fadeInLeft">
              <h3
                className="flex items-center bg-blue-950  text-white  font-semibold text-xl lg:text-2xl px-4 py-2 my-2 cursor-pointer"
                onClick={() => toggleVisibility(i)}
              >
                <div className="flex  space-x-1"> {e.H4}</div>

                <div className=" ml-auto px-6 ">
                  {" "}
                  {/* Added ml-auto for margin-left: auto */}
                  {/* <svg
                  className="w-2.5 h-2.5 ms-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 12 12"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    // d="M1 6h10M6 1v10"
                    d={visibleItems[i] ? "M1 1h10" : "M1 6h10M6 1v10"}
                  />
                </svg> */}
                  <svg
                    className="w-2.5 h-2.5 ms-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 12 7"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d={visibleItems[i] ? "M1 6 6 1 11 6" : "M1 1 5 5 9 1"}
                    />
                  </svg>
                </div>
              </h3>

              {visibleItems[i] && (
                <div className="bg-gray-200 py-5 px-2 transition duration-150 ease-out ">
                  {e.pera}
                  
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq_component;
