import React from "react";


import Header_for_faq_privacy from "../Component/header_for_faq_privacy";

const PrivacyPolicy = () => {
  return (
    <div>
      <Header_for_faq_privacy
      pera1={"Privacy Policy"}
      pera2={"JK Infosys Global"}

      />
      {/* <div className="  text-center  py-16 ">
        <p className="py-5  text-3xl lg:text-5xl bebas-neue-regular text-blue-950">
          Privacy Policy
        </p>
        <p className="py-5 text-center  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          JK Infosys Global
        </p>
      </div> */}
      <div className="px-5 md:px-10 py-10 lg2:px-32 lg3:px-72 ">
        <p className=" text-xl ">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You respond to
          our advertisements and tells You about Your privacy rights and how the
          law protects You. We use Your Personal Data to contact and support
          you, as well as to provide and improve the Service. By using the
          Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>
        <p className="py-10 text-xl text-center lg:text-2xl bebas-neue-regular text-blue-950">
          Interpretation and Definitions
        </p>

        <h3 className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          Interpretation
        </h3>

        <p className="text-xl ">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.{" "}
        </p>
        {/* <p className="text-xl text-blue-900 text-center font-semibold">One inspirational line</p> */}
        <p className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          Definitions
        </p>
        <p className="  text-xl   font-semibold ">
          For the purposes of this Privacy Policy:
        </p>

        <div class=" bg-gray-100  py-5">
          <ul class="list-disc px-10 text-lg leading-8">
            <li>
              Company (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to JK Infosys Global.
            </li>
            <li>
              Device means any device that can access the Service such as a
              computer, a cellphone or a digital tablet.
            </li>
            <li>
              Personal Data is any information that relates to an identified or
              identifiable individual.
            </li>
            <li>
              Service refers to the advertisement, website, or application.
            </li>
            <li>
              Service Provider means any natural or legal person who processes
              the data on behalf of the Company. It refers to third-party
              companies or individuals employed by the Company to facilitate the
              Service, to provide the Service on behalf of the Company, to
              perform services related to the Service, or to assist the Company
              in analyzing how the Service is used.
            </li>
            <li>
              Usage Data refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              You means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
          </ul>
        </div>
        <p className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          Collecting and Using Your Personal Data
        </p>

        <h3>Types of Data Collected</h3>
        <div class=" bg-gray-100   py-5">
          <h3 className="py-2">Personal Data</h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul class="list-disc px-10 text-lg leading-8">
            <li>First name and last name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Location details such as your address, city, or country</li>
            <li>
              Any other data such as personal preferences, requirements, or
              comments
            </li>
          </ul>
        </div>
        <p className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
          Use of Your Personal Data{" "}
        </p>
        <p className=" py-4">
          The Company may use Personal Data for the following purposes:
        </p>

        <ul class="list-disc px-10 text-lg leading-8">
          <li>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li>
            To manage Your Account: to manage Your registration as a user of the
            Service. The Personal Data You provide can give You access to
            different functionalities of the Service that are available to You
            as a registered user.
          </li>
          <li>
            For the performance of a contract: the development, compliance, and
            undertaking of the purchase contract for the products, items, or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application's push notifications regarding updates or informative
            communications related to the functionalities, products, or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers, and general information
            about other goods, services, and events which we offer that are
            similar to those that you have already purchased or inquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns, and to evaluate and
            improve our Service, products, services, marketing, and your
            experience.
          </li>
        </ul>
        <p className="py-5    lg:text-2xl bebas-neue-regular text-blue-950">
          We may share Your personal information in the following situations:
        </p>

        <ul class="list-disc px-10 text-lg leading-8">
          <li>
            With Service Providers: We may share Your personal information with
            Service Providers to monitor and analyze the use of our Service, to
            contact You.
          </li>
          <li>
            For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
          </li>
          <li>
            With Affiliates: We may share Your information with Our affiliates,
            in which case we will require those affiliates to honor this Privacy
            Policy. Affiliates include Our parent company and any other
            subsidiaries, joint venture partners, or other companies that We
            control or that are under common control with Us.
          </li>
          <li>
            With business partners: We may share Your information with Our
            business partners to offer You certain products, services, or
            promotions.
          </li>
          <li>
            With other users: when You share personal information or otherwise
            interact in the public areas with other users, such information may
            be viewed by all users and may be publicly distributed outside.
          </li>
          <li>
            With Your consent: We may disclose Your personal information for any
            other purpose with Your consent.
          </li>
        </ul>
        <p className="py-5   lg:text-2xl bebas-neue-regular text-blue-950">
          Retention of Your Personal Data{" "}
        </p>
        <div>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </div>
        <div>
          The Company will also retain Usage Data for internal analysis
          purposes.
        </div>
        <div class="grid grid-flow-row gap-5 py-5  text-lg leading-8">
          <p className="   lg:text-2xl bebas-neue-regular text-blue-950">
            Transfer of Your Personal Data{" "}
          </p>
          <div>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country, or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </div>
          <div>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </div>
          <div>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy, and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place, including the security of Your data and other personal
            information.
          </div>
        </div>
        <p className="py-5   lg:text-2xl bebas-neue-regular text-blue-950">
          Disclosure of Your Personal Data
        </p>

        <div>
          <div className="py-5">
            <strong>Law enforcement</strong>
          </div>

          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>

          <div className="py-5">
            <strong>Other legal requirements</strong>
          </div>

          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul class="list-disc px-10 text-lg leading-8">
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <p className="py-5  text-xl text-center lg:text-2xl bebas-neue-regular text-blue-950">
            Interpretation and Definitions
          </p>

          <h3 className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
            Links to Other Websites
          </h3>

          <p className="text-lg ">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="text-lg ">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.{" "}
          </p>
          <h3 className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
            Changes to this Privacy Policy{" "}
          </h3>

          <p className="text-lg ">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="text-lg">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p className="text-lg ">
            This Privacy Policy was last updated on 2023/11/02 07:39:20
          </p>
          <h3 className="py-5  text-xl lg:text-2xl bebas-neue-regular text-blue-950">
            Contact Us
          </h3>

          <p className="text-xl ">
            If you have any questions about this Privacy Policy, You can contact
            us via email at{" "}
            <a className="text-blue-800" href="mailto:hello@jkinfosysglobal.com" aria-label="mailto:hello@jkinfosysglobal.com">
              hello@jkinfosysglobal.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
