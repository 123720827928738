import React, { useState } from "react";

const SliderClick = () => {
  const arr = [
    {
      id: 1,
      img: "../img/content.png",
      alt: "content",
      p1: "Having a strong online presence depends a lot on the content, which is often overlooked.",
      p2: "Whether it's just a short phrase or a longer piece, what matters is creating content that's easy to understand and clearly explains why your brand or product is special. This is what gets people talking.",
      p3: "Our team, made up of content writing experts, is excellent at writing. We do thorough research on topics and trends to make sure our content fits well with brand marketing.",
      p4: "We love being creative. Our content is unique, funny, and a bit quirky – it always starts interesting conversations.",
      p5: "You can trust us because our writers are experts in the languages they use, so there's no room for mistakes.",
      p6: "And the best part? Our content is friendly to search engines (SEO), and we write it in a way that even a school-going kid can easily understand.",
    },
    {
      id: 2,
      img: "../img/design.png",
      alt: "design",
      p1: "Whether it's a website or a simple graphic, JK INFOSYS GLOBAL stands out with our creative touch. We make designs from scratch, telling a unique story in everything we create. From posters, brochures, logos, and menus, to hoardings and letterheads, we bring artistic flair to all aspects.",
      p2: "Our designs are a blend of fun and simplicity, featuring delightful color combinations!",
      p3: " We stay updated with trends to keep your brand updated.",
      p4: "Our designers are skilled in Photoshop and Illustrator.",
      p5: "We create custom designs that leave a lasting impression on your brand.",
      p6: "We love trying out new things.",
    },
    {
      id: 3,
      img: "../img/code.png",
      alt: "code",
      p1: "We develop websites for your business that look great and do a lot more. They are not just pretty, but also super practical and interactive. Our goal is to make sure you shine online, giving your business a unique identity in the market.",
      p2: "We make custom websites using trending technologies like Node-JS, React-JS, Java-script, WordPress, and many more technologies.",
      p3: "Our websites have lots of features that match your business goals.",
      p4: "We finish projects on time and with great attention to detail.",
      p5: "We use top-notch coding, and strong site structures, and make sure your site does not buffer.",
      p6: "Our designs are so special that they make your business stand out.",
    },
  ];
  const [T, setT] = useState(arr[0]);
  const r = (i = 0) => {
    setT(arr[i]);
  };
  return (
    <div className="lg2:px-32 py-20  lg3:px-72 px-5">
      <h2 className="py-5 text-blue-950 text-center text-2xl lg:text-5xl bebas-neue-regular">
        Why Choose Us
      </h2>
      <div className="grid  divide-x-2 divide-yellow-200 text-center cursor-pointer bg-blue-950 bebas-neue-regular  text-yellow-200 md:text-4xl grid-cols-3 ">
        <a
          onClick={() => r(0)}
          className="hover:bg-yellow-200 duration-500 transition   ease-in-out   hover:text-blue-950 hover:shadow-amber-400 shadow-2xl py-4 px-4 "
        >
          CONTENT
        </a>
        <a
          onClick={() => r(1)}
          className="hover:bg-yellow-200 duration-500 transition   ease-in-out  hover:text-blue-950  hover:shadow-amber-400 shadow-2xl  py-4 px-4  "
        >
          DESIGN
        </a>
        <a
          onClick={() => r(2)}
          className="hover:bg-yellow-200 duration-500 transition   ease-in-out  hover:text-blue-950 hover:shadow-amber-400 shadow-2xl py-4 px-4 "
        >
          CODE
        </a>
      </div>
      <div id={T.id} className="md:grid  gap-10 grid-cols-3">
        <img className=" col-span-1" src={T.img} alt={T.alt} height={"100%"} width={"100%"} />
        <div className=" my-auto text-left text-sm md:text-lg  col-span-2">
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p1}
          </p>
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p2}
          </p>
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p3}
          </p>
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p4}
          </p>
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p5}
          </p>
          <p className="py-1">
            <scan className=" text-yellow-400">&#10004;</scan> {T.p6}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SliderClick;
