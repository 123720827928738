import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import scrollHook from "../Hooks/scrollHook";

import Header1 from "../Component/header1";
import Faq_component from "../Component/faq";

const WebDevelopment = () => {
  const WebDevelopment_arr_1 = [
    {
      title: "WEBSITE REDESIGN",
      pera: "Our creative designers are experts at customizing or restyling your entire website, giving your business a fresh and visually appealing digital presence. Whether starting from scratch or redesigning an existing site, we focus on creating a user-friendly experience that aligns with your brand identity and leaves a lasting impression on your visitors.",
    },
    {
      title: "WEBSITE MAINTENANCE",
      pera: "Crafting a website is only the start; maintaining its excellence is equally crucial. With JK INFOSYS GLOBAL as your support, you can concentrate on your business while we handle your digital presence. We make sure your website stays updated with the latest tools and plugins through ongoing maintenance, ensuring optimal performance.",
    },
    {
      title: "E-COMMERCE WEBSITE DESIGN AND DEVELOPMENT",
      pera: "We specialize in designing and developing eCommerce websites, ideal for businesses looking to present their products to a digital audience. Our aim is to enhance the online shopping experience, making it suitable for your customers.",
    },
    {
      title: "CONTENT MANAGEMENT SYSTEM AND DEVELOPMENT",
      pera: "We create and develop customized content management systems (CMS) for websites that frequently upload and publish substantial content and data, such as blogs, news sites, e-magazines, and more.",
    },
  ];
  const WebDevelopment_arr_3 = [
    {
      H4: "Why is a well-developed website important for my business?",
      pera: "Having a good website is super important for your business. It's like your digital storefront that's open 24/7, letting people check out what you offer anytime. A well-made website not only gives a great first impression but also makes your business look trustworthy. It's like a virtual place where you can show off your products or services and tell your story. Think of your website as a friendly guide for customers, making it easy for them to learn about your business and contact you. It's like having a reliable assistant that works even when you're sleeping! And here's the cool part – a website helps your business stand out in the online world. It's like a tool that not only attracts customers but also lets you see how they behave, so you can make smart decisions to grow your business. So, having a good website isn't just a nice-to-have; it's a must-have for a successful and customer-friendly business.",
    },
    {
      H4: "Can you redesign my existing website for a modern look?",
      pera: "Absolutely! We're really good at making your online stuff look modern and cool. We focus on updating how things appear, like giving your digital home a fresh touch. Whether it's making your website responsive, adding new info, or giving it a total makeover, we're here to make your online space look awesome for today's crowd. Our main goal is to make your online place not just look good but also feel right for the times we're in. We're like decorators for your digital world, making sure it stands out and feels super modern. Let us be the ones to give your online look a fresh and trendy vibe! ",
    },
    {
      H4: "What role does SEO play in website development?",
      pera: "Think of SEO like a magic trick for your website – it helps it appear higher up when people search on Google for things related to what your business offers. So, when someone looks for stuff similar to what you do, your website has a better chance of popping up near the top. With SEO, we work on making your website climb higher in the list of search results. This way, when someone is searching for something, your business provides, there's a good chance they'll find your website quickly. It's like making your business stand out in a big crowd, so more people notice it when they're looking for things online. In a nutshell, SEO is that helps your website get seen by the right people at the right time. So, incorporating SEO isn't just about making your website look good to search engines; it's about making it stand out on the search engine, ensuring more people discover and explore what you have to offer. ",
    },
    {
      H4: "Can I update my website content after it's developed?",
      pera: "Absolutely! You can definitely update your website content even after it's been developed. We provide a user-friendly Content Management System (CMS) that empowers you to make changes to your website without needing technical expertise. With the CMS, you can easily add new content, modify text, upload images, and make updates to keep your website current and relevant.Think of the CMS as a toolkit that puts you in control of your website's content. It's designed to be intuitive and straightforward, allowing you to manage your online presence without any hassle. Whether you want to add new products, update information, or share the latest news, the CMS makes it simple for you to keep your website up-to-date. So, even after the initial development, you have the flexibility to make changes and ensure your website reflects the latest aspects of your business.",
    },
    {
      H4: "What ongoing support and maintenance do you offer?",
      pera: "We provide comprehensive ongoing support and maintenance to ensure your website remains in top-notch condition. Our services include regular updates, security monitoring, and technical assistance to address any issues that may arise. With our maintenance packages, you can enjoy peace of mind knowing that your website is well taken care of.Our support extends beyond mere technical aspects. We're here to assist you with content updates, feature enhancements, and any adjustments you may need. Whether it's troubleshooting technical glitches or implementing new functionalities, our team is dedicated to keeping your website running smoothly. Additionally, we offer regular performance evaluations and analytics reviews to ensure your website continues to meet its goals. Our goal is to provide you with a hassle-free experience, allowing you to focus on your business while we handle the technicalities of maintaining a reliable and high-performing website.",
    },
  ];

  return (
    <div className=" bg-gray-100  text-black">
      <Helmet>
        <title>Website Development Agency in Ahmedabad </title>
        <meta
          name="description"
          content="Web design is the creation of websites and pages to reflect a
          company's brand and information and ensure a user-friendly
          experience."
          data-rh="true"
        />
      </Helmet>
      <Header1
        img={"../img/new_website_header.png"}
        pera={`"We Build Website that Bring Results"`}
        alt={"new_website_header"}
      />
      <div className="lg2:px-32 px-5 lg3:px-72 wow animate__animated animate__fadeInLeft">
        <div className="py-12 text-center text-xs md:text-lg  ">
          <h1 className="h1Classes">TOP WEBSITE DEVELOPMENT AGENCY IN AHMEDABAD</h1>

          <p className="py-5 text-justify">
            In today's digital age, having an online presence through a business website is not just
            beneficial but essential. Your website serves as a powerful tool to tell the story of
            your business, providing a digital showcase for your services and products. It goes
            beyond mere advertising; it becomes a platform for meaningful engagement with both your
            existing customer base and potential clients.{" "}
            {/*              <NavLink onClick={scrollHook} to=   
"/">
                  {" "}
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
              search engine optimization company in Ahmedabad
           </span>
                </NavLink>{" "}{" "} */}
            {/* . First of all, we should understand, what SEO is? */}
            {/* <h2 className="py-5 text-blue-950 text-center text-2xl lg:text-3xl bebas-neue-regular">
            JK Infosys your ultimate SEO service partner in Ahmedabad
          </h2> */}
          </p>

          <div className="lg:grid grid-cols-2 gap-10">
            <p className=" py-5 md:py-10 text-justify">
              With the widespread use of smartphones and easy access to the internet, having a
              website becomes a strategic move for your business. It transforms your reach, allowing
              you to transcend geographical borders and connect with customers globally. In a world
              where consumers are increasingly turning to online platforms for information and
              services, a well-designed and accessible website becomes the gateway for your business
              to thrive in the digital landscape.
              <br />
              <br />
              At JK INFOSYS GLOBAL, recognized as the premier{" "}
              <NavLink onClick={scrollHook} to="/">
                {" "}
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  website design and development company in Ahmedabad
                </span>
              </NavLink>{" "}
              , we don't just create static websites. We specialize in crafting dynamic and highly
              customized sites and portals that seamlessly adapt to various devices, from small
              smartphones to large desktop screens. Whether you're aiming for an E-commerce platform
              to sell products, a branding site to showcase services and accomplishments, or a
              combination of both, we tailor our solutions to align with your ideas and
              requirements, adding a touch of creative innovation from our end.
            </p>

            <img
              width={"100%"}
              height={"100%"}
              src="../img/with_content_website_image.png"
              alt="with_content_website_image"
            />
          </div>

          <p className="py-5 text-justify">
            Our websites stand out with eye-catching designs, ensuring a clutter-free and seamless
            experience for visitors. Uniquely customized to captivate your audience, they go beyond
            aesthetics, strategically designed to retain visitors and successfully guide them
            towards becoming customers. When you choose a website from JK INFOSYS GLOBAL for your
            business, you're not just getting a visually appealing platform – you're getting a
            highly functional and interactive portal. Crafted on versatile technologies like
            React-JS, Node-JS, WordPress, and Trending Web Technologies. Our websites are geared to
            enhance your online presence and drive meaningful engagement with your audience.
          </p>

          <p className="py-5 text-justify">
            You might be thinking any{" "}
            <NavLink onClick={scrollHook} to="/">
              {" "}
              <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                web design or development company in Ahmedabad{" "}
              </span>
            </NavLink>{" "}
            can handle this, but here's what sets us apart. Our developer teams deliver projects
            within tight timelines with exacting precision, ensuring you receive a custom website
            loaded with features that perfectly align with your digital business objectives. We
            design websites tailored for blogs, branding, e-commerce, and product advertising,
            incorporating reliable coding and strong architecture. This guarantees your business a
            visually pleasing and SEO-optimized digital presence.
          </p>
          <h2 className="py-5 text-center text-blue-950 text-3xl lg:text-5xl bebas-neue-regular">
            Ahmedabad-based Website Development Agency
          </h2>
          <p className="py-5 text-justify">
            Imagine a website that's not just visually stunning but also a strategic powerhouse,
            attracting leads, converting visitors, and boosting your brand. We build those. From
            wonderful innovation ideas to crafting pixel-perfect designs, developing user-friendly
            interfaces, and empowering you with the knowledge to manage it all, we're your one-stop
            shop for web success.
          </p>
          <div className=" py-5  content-center   wow animate__animated animate__fadeInLeft">
            <h2 className=" text-4xl text-center py-2 text-blue-950 font-semibold  underline underline-offset-8 ">
              OUR WEB DEVELOPMENT SERVICES
            </h2>
            <div className="grid   lg:grid-cols-2">
              {WebDevelopment_arr_1.map((e, i) => (
                <div
                  className={`my-2 border-2  md:mx-3 rounded-3xl shadow-2xl ${
                    i === 0 || i === 3
                      ? "border-blue-950 shadow-blue-100"
                      : " border-yellow-400  shadow-yellow-100"
                  } border-blue-950  wow animate__animated animate__fadeInLeft`}
                >
                  <h3
                    className={`w-full md:flex  rounded-t-2xl  ${
                      i === 0 || i === 3 ? "bg-blue-950 text-white" : "bg-yellow-400 text-blue-950"
                    }    font-semibold text-xl  md:text-xl py-5 px-5`}
                  >
                    {e.title}
                  </h3>
                  <p className=" py-5 px-5 md:h-72 leading-8 ">{e.pera}</p>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2 className="py-5 text-blue-950 text-center text-2xl lg:text-3xl bebas-neue-regular">
              WHY CHOOSE US?
            </h2>
            {/* <div className="grid grid-cols-4 gap-10"> */}
            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <img
                width={"100%"}
                height={"100%"}
                className=" "
                src="../img/tailored_solution_to_your_busines.png"
                alt="tailored_solution_to_your_busines"
              />
              <p className="py-5 my-auto  col-span-5 text-justify">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  Tailored Solutions for Your Business:
                </span>{" "}
                At JK INFOSYS GLOBAL{" "}
                <NavLink onClick={scrollHook} to="/">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Website Development company in Ahmedabad
                  </span>
                </NavLink>{" "}
                , we understand that each business is unique. Our website development services are
                tailored to meet the specific needs and goals of your business. Whether you're a
                startup, small business, or a large enterprise, we've got the expertise to create a
                website that aligns perfectly with your vision.
              </p>
            </div>

            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6 ">
              <p className="py-5 my-auto col-span-5 text-justify">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  User-Friendly Designs experience:
                </span>{" "}
                At JK INFOSYS GLOBAL A website should not just look good but also offer a seamless
                user{" "}
                <NavLink onClick={scrollHook} to="/">
                  <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                    Website Development company in Ahmedabad
                  </span>
                </NavLink>{" "}
                , our designs prioritize user-friendliness, ensuring that visitors to your site have
                a positive and engaging interaction. This, in turn, increases the chances of
                converting visitors into customers.
              </p>
              <img
                width={"100%"}
                height={"100%"}
                className=""
                src="../img/user_friendly_design_experience.png"
                alt="user_friendly_design_experience"
              />
            </div>
            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <img
                width={"100%"}
                height={"100%"}
                src="../img/cutting_edge_technology.png"
                alt="cutting_edge_technology"
              />
              <p className="py-5 my-auto  col-span-5">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  Cutting-Edge Technology:
                </span>{" "}
                Our development team utilizes cutting-edge tools and frameworks to ensure that your
                website is not only visually appealing but also technologically advanced. This
                ensures a smooth and efficient performance.
              </p>
            </div>
            {/* <div className="md:grid grid-cols-2 gap-16"> */}

            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <p className="py-5  my-auto  col-span-5">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  Mobile Responsiveness:
                </span>{" "}
                With the majority of internet users accessing websites through mobile devices,
                having a mobile-responsive site is crucial. Our development services prioritize
                mobile compatibility, guaranteeing that your website looks and functions seamlessly
                across various devices.
              </p>
              <img
                width={"100%"}
                height={"100%"}
                src="../img/mobile_responsive.png"
                alt="mobile_responsive"
              />
            </div>
            {/* </div>{" "} */}
            {/* <img width={"100%"}  height={"100%"} src="../img/with_content_website_second_image.png" alt="with_content_website_second_image" /> */}
            {/* <div className="grid grid-cols-3 gap-10"> */}
            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <img
                width={"100%"}
                height={"100%"}
                src="../img/seo_friendly.png"
                alt="seo_friendly"
              />
              <p className="py-5   my-auto  col-span-5">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  SEO-Friendly Structure:
                </span>{" "}
                What good is a website if it can't be found? Our development incorporates SEO best
                practices right from the start. This helps your website rank higher in search engine
                results, making it easier for potential customers to discover your business.
              </p>
            </div>

            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <p className="py-5  my-auto  col-span-5">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  Scalability for Growth:
                </span>{" "}
                As your business expands, so should your website. We build scalable websites that
                can grow with your business. Whether you're adding new products, services, or
                features, our development ensures that your website can easily accommodate these
                changes.
              </p>
              <img
                width={"100%"}
                height={"100%"}
                src="../img/scalabality_for_growth.png"
                alt="scalabality_for_growth"
              />
            </div>

            <div className="md:grid  border-4  my-5 shadow-xl px-5 rounded-3xl grid-cols-6">
              <img
                width={"100%"}
                height={"100%"}
                src="../img/realiable_support.png"
                alt="realiable_support"
              />
              <p className="py-5 text-justify my-auto  col-span-5">
                <span className="hover:bg-blue-950 underline hover:text-white text-blue-950 font-semibold">
                  Reliable Support and Maintenance:
                </span>{" "}
                Our commitment doesn’t end with the launch of your website. We offer ongoing support
                and maintenance services to ensure that your website continues to operate smoothly.
                Any issues or updates you need – we've got you covered.
              </p>
            </div>
            {/* </div> */}
          </div>
        </div>
        <Faq_component
          arr={WebDevelopment_arr_3}
          pera={"FREQUENTLY ASKED QUESTIONS ABOUT WEBSITE DEVELOPMENT SERVICES"}
        />
      </div>
    </div>
  );
};

export default WebDevelopment;
