import React from "react";
import { Helmet } from "react-helmet-async";
import Section from "../Component/Cartsection";
import { NavLink } from "react-router-dom";
import scrollHook from "../Hooks/scrollHook";
import Header1 from "../Component/header1";
import Faq_component from "../Component/faq";

const DigitalMarketing = () => {
  const DigitalMarketing_arr_1 = [
    {
      title: "LOCAL MARKETING",
      pera: "Attract local customers effectively with our targeted online marketing. We specialize in creating a strong online presence tailored to your customer needs. Trust us to boost your brand's recognition and success, engaging customers and fostering lasting loyalty within your locale.",
    },
    {
      link: "/seo",

      title: "SEO",
      pera: "Elevate your online presence with our powerful SEO services. We specialize in optimizing your digital footprint through advanced strategies like keyword optimization and content enhancement.",
    },
    {
      link: "/social-media-marketing",

      title: "SOCIAL MEDIA MARKETING",
      pera: "Boost online presence with our social media marketing! We promote your brand on popular platforms, engaging a diverse audience through compelling campaigns. Using social media's broad reach, we ensure your brand attracts potential customers, delivering impactful results.",
    },

    {
      title: "Content Marketing",
      pera: "Optimize your online impact with our SEO-driven content marketing solutions. Our expert team crafts engaging, keyword-rich content to attract your audience and secure high search engine rankings. Elevate your brand's digital presence and outshine the competition with our tailored content marketing strategies for SEO success.",
    },
    {
      title: "EMAIL MARKETING",
      pera: "Boost your business outreach with our personalized email marketing. Personalized content boosts engagement, drives more sales, and strengthens customer loyalty by connecting with your audience.",
    },
    {
      title: "MOBILE MARKETING",
      pera: "Expand your reach with Mobile Marketing – carefully crafting campaigns for visibility on mobile devices like apps or targeted SMS. Easily connect with your audience on the go and boost engagement through personalized mobile experiences. Our strategies use the latest trends and tech, making sure your brand stands out on your customers' mobile screens.",
    },
    {
      title: "Pay-Per-Click (PPC) Advertising",
      pera: "Attain immediate visibility through focused PPC campaigns. Our data-centric strategy guarantees your ads reach the ideal audience, maximizing return on investment and enhancing the effectiveness of your digital advertising effort.",
    },
    {
      title: "Affiliate marketing",
      pera: "Welcome to JK INFOSYS GLOBAL, your trusted partner in affiliate marketing success! Our cutting-edge affiliate marketing services are designed to drive results and maximize your online revenue. Whether you're a brand looking to expand your reach or an affiliate seeking partnerships, we've got you covered.",
    },
    {
      title: "Online Reputation Management",
      pera: "Boost your organization's image and profits with our carefully designed reputation management services. We strategically shape a positive public perception, paving the way for sustained growth and success.",
    },
  ];
  const faq_arr_digmarketing = [
    {
      H4: "What is digital marketing? ",
      pera: "Digital marketing, also called online marketing, is the promotion of brands to connect with potential customers using the internet and other forms of digital communication. This includes not only email, social media, and web-based advertising, but also text and multimedia messages as a marketing channel. It plays an important role in broadening reach, increasing visibility, and driving growth by utilizing various online channels and platforms. Our company specializes in providing a range of services to empower businesses in the digital world.",
    },
    {
      H4: "What services do you provide? ",
      pera: "The services we offer include Search Engine Optimization (SEO), which involves optimizing your website to improve its visibility on search engines, thereby driving organic traffic and establishing industry authority. Social media marketing is another key service that focuses on creating and implementing strategies to engage and grow your audience across various social media platforms. Content creation, an integral part of digital marketing, involves developing compelling and relevant content to capture the attention of your target audience. We also Design and Develop Websites from E-commerce to Personal Websites we have experts for all.  Additionally, paid advertising allows businesses to reach specific demographics and target audiences through paid promotions on platforms like Google Ads or social media. We help business to increases their ROI through our paid advertising service.",
    },
    {
      H4: "How can digital marketing benefit my business? ",
      pera: "Digital marketing provides numerous benefits for businesses, including enhancing online presence, attracting targeted audiences, generating leads, and ultimately increasing sales with measurable results. To ensure the effectiveness of our strategies, we tailor them specifically to your business by conducting a thorough analysis of your industry, business model, and target audience. This ensures that our efforts are aligned with your goals and objectives.",
    },

    {
      H4: "What is SEO's importance? ",
      pera: "The importance of SEO cannot be overstated, as it is a fundamental aspect of digital marketing. SEO optimization involves fine-tuning your website to meet the criteria of search engine algorithms, leading to improved visibility, increased organic traffic, and the establishment of authority within your industry. SEO is like the backbone of digital marketing—it's super important! Think of it as giving your website a makeover to impress search engines like Google. This makeover, called SEO optimization, involves tweaking your site to match what these search engine robots love. The result? Your website becomes a shining star on the internet.Picture this: people looking for businesses like yours online, and thanks to SEO, your website pops up more often. It's not just about getting noticed; it's about becoming the go-to expert in your industry.  ",
    },

    {
      H4: "How is campaign success measured?",
      pera: "Checking how well your digital marketing is doing is super important for making it even better. We use special tools called Key Performance Indicators (KPIs) to see how things are going.Imagine your website is like a store, and website traffic is like counting how many people walk in. Conversion rates tell us how many of those visitors become customers, just like knowing how many people who enter a store end up buying something. Social media engagement is like keeping an eye on how much people like, share, and comment on your posts.These KPIs are like your digital dashboard, showing us what's working well and what we can improve. It's not just about numbers; it's about making sure your digital strategies are connecting with people. Think of it like giving your digital marketing a report card to make it even more awesome!",
    },
    {
      H4: "What sets your company apart?  ",
      pera: "What makes our company special is how we mix creativity with smart strategies using data. It's like putting together strategic ideas with precise plans, making our approach one-of-a-kind in the busy digital marketing world.We really love working with our clients. It's not just about what we think; it's about what you think too. Your ideas are super important, and we make sure they're part of everything we do. It's like making a cool project together.In the big world of digital marketing, our standout feature is our promise to show you real, measurable results. We don't just talk about doing cool stuff; we make sure that what we do actually works and makes a difference you can see. Think of us as guides, helping you navigate the digital world with a map that leads to clear and impactful outcomes.Our strength lies in bringing together creativity, teamwork, and a focus on results. We're not just running campaigns; we're building digital adventures that leave a lasting mark.",
    },
    {
      H4: "Can I choose specific services?   ",
      pera: "Whether you're looking for a comprehensive digital marketing package or specific services to meet your business goals, we offer the flexibility for you to choose individual services based on your unique needs. Starting with us is simple – reach out through our website or give us a call to initiate a consultation where we can discuss how our services can be aligned with the specific needs of your business.",
    },
  ];
  return (
    <div className=" bg-gray-100 ">
      <Helmet>
        <title> Best Digital Marketing Agency</title>
        <meta
          name="description"
          content="Social media marketing is a form of digital marketing that leverages
          the power of popular social media networks to achieve your marketing
          and branding goals."
          data-rh="true"
        />
      </Helmet>

      <Header1
        img={"../img/new_digital_marketing_header.png"}
        alt="new_digital_marketing_header"
        height="100%"
        width="100%"
        heading={"BEST DIGITAL MARKETING AGENCY"}
        pera={`"From impressions to conversions-we turn eyeballs into customers with precision."`}
      />

      <div className="  responsiveClasses wow animate__animated animate__fadeInLeft ">
        <div className="pageContentClasses ">
          <div>
            <h1 className="h1Classes ">TOP DIGITAL MARKETING AGENCY IN AHMEDABAD</h1>

            <p>
              As a well-known{" "}
              <NavLink onClick={scrollHook} to="/">
                {" "}
                <span className="spanClasses">digital marketing company in Ahmedabad</span>
              </NavLink>{" "}
              , we provide top-notch solutions to our clients. Our services cover local marketing,
              SEO, online advertising, social media marketing, mobile marketing, email marketing,
              conversion optimization, and branding.
            </p>
            <p>
              Being a trusted{" "}
              <NavLink onClick={scrollHook} to="/">
                {" "}
                <span className="spanClasses">digital marketing company in Ahmedabad</span>
              </NavLink>{" "}
              , our experience as a digital marketing consultant guarantees that we have the
              know-how to offer services that meet our clients' evolving needs. We promise to
              deliver excellent and quality services within the agreed-upon time frame, ensuring our
              clients receive the best possible support.
            </p>
            <h2 className="h2Classes">OUR TOP DIGITAL MARKETING SERVICES IN AHMEDABAD</h2>

            <div className="md:grid py-5 text-right grid-cols-2">
              <div className=" my-auto">
                <img
                  src="../img/with_content_digital-marketing_two.png"
                  alt="marketing_two"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className=" my-auto">
                <p className="lg:px-10">
                  Explore a wide range of digital marketing services at JK INFOSYS GLOBAL that are
                  tailored to boost your organization's growth by increasing sales and enhancing
                  your reputation. Our services go beyond meeting expectations – we strive to exceed
                  them, making your brand stand out online. Whether it's strategic marketing
                  campaigns or targeted online efforts, we're committed to positioning your
                  organization for success through a comprehensive approach covering all aspects of
                  digital marketing. Join forces with us to unlock your business's full potential,
                  embarking on a journey towards improved visibility, higher sales, and a stellar
                  industry reputation. At JK INFOSYS GLOBAL, we're more than just a service
                  provider; we're your strategic partner in navigating the dynamic world of digital
                  marketing, guiding your organization towards new heights of success.
                </p>
              </div>
            </div>
          </div>

          <Section arr={DigitalMarketing_arr_1} title={"OUR SERVICES"} />

          <div className="">
            <h2 className="h2Classes ">WHY CHOOSE US </h2>
          </div>
          <div className=" px-5  ">
            <div className="md:grid grid-cols-9 gap-6 py-10">
              <div className="col-start-2 col-end-5  row-start-1 row-span-2 py-5 lg:py-0 ">
                <div className="flex text-right">
                  <p className="font-bold text-blue-950 underline  text-lg lg:text-xl">
                    PROVEN EXPERTS
                  </p>
                  <img
                    className="px-5"
                    src="../img/proven_expert.png"
                    alt="proven_expert"
                    width="80"
                    height="80"
                  />
                </div>
                <p>
                  Trust our experienced team known for successful digital campaigns in various
                  industries.{" "}
                </p>
              </div>

              <div className="col-start-6 col-end-9  row-start-1 row-span-2">
                <div className="flex  ">
                  <img
                    className="hidden md:block "
                    src="../img/your_sucess_matter.png"
                    alt="your_sucess_matter"
                    width="80"
                    height="80"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl md:px-5">
                    YOUR SUCCESS MATTERS
                  </p>
                  <img
                    className=" md:hidden px-5"
                    src="../img/your_sucess_matter.png"
                    alt="your_sucess_matter"
                    width="80"
                    height="80"
                  />
                </div>
                <p>We communicate openly, adapting strategies based on your feedback. </p>
              </div>
            </div>

            <div className="md:grid grid-cols-6 grid-row-3 gap-6  ">
              {/* 
            <div className="col-start-2 col-end-4  row-start-1 row-span-2 py-5 lg:py-0 ">
              <div className="flex text-right">
                <p className="font-bold text-blue-950 underline  text-lg lg:text-xl">PROVEN EXPERTS</p>
                <img className="px-5" src="../img/proven_expert.png" alt="proven_expert" width="100%" height="100%" />
              </div>
              <p>
                Trust our experienced team known for successful digital
                campaigns in various industries.{" "}
              </p>
            </div> */}
              <div className="col-start-1 col-end-3  row-start-1   py-5 lg:py-0 ">
                <div className="flex text-right">
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl">
                    TAILORED STRATEGIES
                  </p>
                  <img
                    className="px-5"
                    src="../img/tailored_stretagy.png"
                    alt="tailored_stretagy"
                    width="80"
                    height="80"
                  />
                </div>
                <p className="py-2">
                  Get personalized plans aligned with your goals for the best results.{" "}
                </p>
              </div>

              <div className="col-start-1 col-end-3  row-start-2 py-5 lg:py-0 ">
                <div className="flex  text-right">
                  <p className="font-bold  text-blue-950 underline text-lg lg:text-xl">
                    ALL-IN-ONE SERVICES
                  </p>
                  <img
                    className="px-5"
                    src="../img/all_in_one_services.png"
                    alt="all_in_one_services"
                    width="80"
                    height="80"
                  />
                </div>
                <p>
                  We cover SEO, social media, content, and ads – everything for your digital needs.{" "}
                </p>
              </div>

              <div className=" py-5 lg:py-0 col-start-1 col-end-3  row-start-3 row-span-2">
                <div className="flex  text-right">
                  <p className="font-bold  text-blue-950 underline text-lg lg:text-xl">
                    EASY-TO-TRACK RESULTS
                  </p>
                  <img
                    className="px-5"
                    src="../img/easy_to_track_result.png"
                    alt="easy_to_track_result"
                    width="80"
                    height="80"
                  />
                </div>
                <p>Transparent analytics help you see the success of your campaigns. </p>
              </div>

              {/* <div className=" py-5 lg:py-0  col-start-3 col-end-5  row-start-9 row-span-2">
              <div className="flex text-right ">
                <p className="font-bold text-blue-950 underline text-lg lg:text-xl">CREATIVE SOLUTIONS</p>
                <img className="px-5" src="../img/creative_solution.png" alt="creative_solution" width="100%" height="100%" />
              </div>
              <p>
              Stand out with our innovative ideas that follow the latest trends.{" "}
              </p>
            </div>  */}

              <div className="py-5 lg:py-0  col-start-3 col-end-5 row-start-1 row-span-3">
                <img
                  className=""
                  src="../img/new_digital_marketing_header.png"
                  alt="new_digital_marketing_header"
                  width="100%"
                  height="100%"
                />
              </div>

              {/* <div className="col-start-7 col-end-9  row-start-1 row-span-2">
              <div className="flex  ">
              <img className="" src="../img/your_sucess_matter.png" alt="your_sucess_matter" width="100%" height="100%" />
                <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">YOUR SUCCESS MATTERS</p>
                
              </div>
              <p>
              We communicate openly, adapting strategies based on your feedback.{" "}
              </p>
            </div> */}

              <div className=" py-5 lg:py-0  col-start-5 col-end-7 row-start-1   ">
                <div className="flex  ">
                  <img
                    className=""
                    src="../img/flexible_option.png"
                    alt="flexible_option"
                    width="50"
                    height="50"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">
                    FLEXIBLE OPTIONS
                  </p>
                </div>
                <p>Choose what you need or go for a complete package that fits your budget. </p>
              </div>

              <div className=" py-5 lg:py-0 col-start-5 col-end-7 row-start-2 ">
                <div className="flex  ">
                  <img
                    className=""
                    src="../img/on_time_delivery.png"
                    alt="on_time_delivery"
                    width="50"
                    height="50"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">
                    ON-TIME DELIVERY
                  </p>
                </div>
                <p>We value your time, ensuring prompt service to keep your plans on track. </p>
              </div>

              <div className="py-5 lg:py-0 col-start-5 col-end-7 row-start-3 row-span-2">
                <div className="flex  ">
                  <img
                    className=""
                    src="../img/always_improving.png"
                    alt="always_improving"
                    width="50"
                    height="50"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">
                    ALWAYS IMPROVING:
                  </p>
                </div>
                <p>We stay updated on trends, tech changes, always refining our strategies. </p>
              </div>

              {/* <div className="py-5 lg:py-0 col-start-7 col-end-9 row-start-9 row-span-2">
              <div className="flex  ">
              <img className="" src="../img/ready_to_help.png" alt="ready_to_help" width="100%" height="100%" />
                <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">Ready to Help:</p>
                
              </div>
              <p>
              Our support team is here for you, ensuring a smooth partnership for your digital success.{" "}
              </p>
            </div> */}
            </div>

            <div className="md:grid grid-cols-9 gap-6 py-10">
              <div className=" py-5 lg:py-0  col-start-2 col-end-5  row-start-9 row-span-2">
                <div className="flex text-right ">
                  <img
                    className=" md:hidden "
                    src="../img/creative_solution.png"
                    alt="creative_solution"
                    width="50"
                    height="50"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">
                    CREATIVE SOLUTIONS
                  </p>
                  <img
                    className="hidden md:block"
                    src="../img/creative_solution.png"
                    alt="creative_solution"
                    width="50"
                    height="50"
                  />
                </div>
                <p>Stand out with our innovative ideas that follow the latest trends. </p>
              </div>

              <div className="py-5 lg:py-0 col-start-6 col-end-9 row-start-9 row-span-2">
                <div className="flex  ">
                  <img
                    className=""
                    src="../img/ready_to_help.png"
                    alt="ready_to_help"
                    width="50"
                    height="50"
                  />
                  <p className="font-bold text-blue-950 underline text-lg lg:text-xl px-5">
                    READY TO HELP:
                  </p>
                </div>
                <p>
                  Our support team is here for you, ensuring a smooth partnership for your digital
                  success.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <Faq_component
          arr={faq_arr_digmarketing}
          pera={"FREQUENTLY ASKED QUESTIONS ABOUT DIGITAL MARKETING SERVICES"}
        />
      </div>
    </div>
  );
};

export default DigitalMarketing;
